import { useEffect, useState } from 'react';
import { Box, Button, Menu, Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import DataGridTable from './datagridTable';

import '../../pages/usermanagement/usermanagement.css';


const UserManagement = () => {
  const [rows, setRows] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const [dealType, setDealType] = useState(3)
  const [dealStatus, setDealStatus] = useState(4)
  const [statusChange, setStatusChange] = useState(true)


  const roleType = {
    0: "Admin",
    1: "Staff",
    2: "Employee "
  }

  useEffect(() => {
    setLoading(false);

  }, [page, pageSize, dealType, dealStatus, statusChange]);



  const DropDownButton = (props) => {

    const dropDownStatus = {
      0: {
        backgroundColor: "#FFBBBA",
        buttonText: props.availStatus ? 'Unavailable' : 'Deactive',
        buttonTextColor: '#B51918',
      },
      1: {
        backgroundColor: "#C5DDBB",
        buttonText: props.availStatus ? 'Available' : 'Active',
        buttonTextColor: '#316719',
      }
    }



    const [anchorEl, setAnchorEl] = useState(null);
    const { rowData } = props;
    const [buttonColor, setButtonColor] = useState("");
    const [buttonTextColor, setButtonTextColor] = useState("");
    const [buttonText, setButtonText] = useState("");


    const menuStyle = {
      background: `${buttonColor}`,
      color: `${buttonTextColor}`,
      pointerEvents: 'auto',
    };

    const buttonStyle = {
      pointerEvents: 'auto',
    };

    return (
      <div>
        <Button
          sx={{
            ...buttonStyle,
            width: "fit-content",
            height: "35px",
            color: `${buttonTextColor}`,
            borderColor: "white",
            backgroundColor: `${buttonColor}`,
            borderRadius: "0.5rem",
            "&:hover": {
              borderColor: "white",
              backgroundColor: `${buttonColor}`,
            },
          }}
          aria-controls="dropdown-menu"
          aria-haspopup="true"

          variant="contained"
          color="primary"
        >
          {buttonText}{" "}
          <KeyboardArrowDownIcon sx={{ color: `${buttonTextColor}` }} />
        </Button>
        <Menu
          id="dropdown-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}

          PaperProps={{ style: menuStyle }}
        >



        </Menu>
      </div>
    );
  };


  const columns = [
    {
      field: "uniqueId",
      headerName: "ID",
      width: 80,
      editable: false,

    },
    {
      field: "name",
      headerName: "User Name",
      width: 200,
      editable: false,

    },
    {
      field: "email",
      headerName: "Mail",
      width: 200,
      editable: false,
    },
    {
      field: "mobile",
      headerName: "Mobile Number",
      width: 200,
      editable: false,
    },
    {
      field: "roles",
      headerName: "Role",
      width: 150,
      editable: false,

    },
    {
      field: "status",
      headerName: "Status",
      width: 170,
      editable: false,

    },
    {
      field: "Availability Status",
      headerName: "Availability Status",
      width: 200,
      editable: false,

    },
    {
      field: "Actions",
      headerName: "Action",
      width: 100,
      editable: false,

    },
  ];
  return (

    <Box
      sx={{
        className: 'box1',
        width: "fitContent",
        display: "flex",
        flexDirection: "column",
        height: "fit-content",
        borderRadius: "5px",
        marginLeft: "10px",
        marginTop: "50px",
        border: "1px solid black"
      }}
    >
      <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '15px', borderBottom: "1px solid lightgrey" }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant='h4' sx={{ color: 'black' }}>User Management</Typography>
          <br></br>

        </Box>

        <Button sx={{
          width: "fit-content",
          height: "fit-content",
          color: "white",
          borderColor: "white",
          backgroundColor: "black",
          borderRadius: "0.5rem",
          "&:hover": {
            borderColor: "white",
            backgroundColor: "black"
          },
        }} variant='contained' >New User</Button>


      </Box>
      <DataGridTable className="gridTable"
        height="fitContent"
        width="100%"
        data={rows || []}
        columns={columns}
        pagination={false}
        pageSize={pageSize}
        rowCount={totalRows}

      />
    </Box>
  );

};

export default UserManagement;
