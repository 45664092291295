import { Popover } from "@mui/material";
import React from "react";

export const HoverComponent = ({ props }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  let [open, setOpen] = React.useState(Boolean);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <div
        onMouseEnter={handleClick}
        style={{
          width: "fit-content",
          display: "flex",
          alignItems: "center",
          height: "100%",
          cursor: "pointer",
          pointerEvents: "auto",
        }}
      >
        <div
          onMouseLeave={handleClose}
          style={{
            gap: "20px",
            display: "flex",
            cursor: "pointer",
            pointerEvents: "auto",
            marginTop: "8px",
          }}
        >
          <img style={{ width: "50px", height: "35px" }} src={props}></img>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            // onMouseLeave={handleClose}
            anchorOrigin={
              {
                // vertical: 'left',
                // horizontal: 'right',
              }
            }
            slotProps={{ paper: { onMouseLeave: handleClose } }}
          >
            <img
              style={{ width: "300px", height: "300px", cursor: "pointer" }}
              src={props}
            ></img>
          </Popover>
        </div>
      </div>
    </>
  );
};
