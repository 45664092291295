import React, { useState, useEffect } from "react";
import { callApi } from "../callApi/api";
import { Header } from "../components";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import { Button, Grid } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DataGridTable from "../components/DataGrid/DataGridTable";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useNavigate } from "react-router-dom";
import { HoverComponent } from "./IamgeHowerComponent";

const Seller = () => {
  const handleAction = (id) => {
    window.location.href = `/sellerDetails?sellerId=${id}`;
  };
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  const handlePageChange = (newPage) => {
    setPage(newPage.page + 1);
    setPageSize(newPage.pageSize);
  };

  const getApi = async () => {
    try {
      let counting = page * pageSize + 1;
      const response = await callApi("get", "seller/getAllSeller");
      console.log(response, "----this is response---");
      const modifiedData = response?.data?.data?.map((data, index) => ({
        ...data,
        _id: counting + index,
      }));

      setRows(modifiedData);
      setTotalRows(response?.data?.data?.length);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getApi();
  }, [page, pageSize]);

  const handleClick = (phone) => {
    // Redirect to WhatsApp with the phone number (if available)
    if (phone) {
      window.open(`https://wa.me/${phone}`, "_blank");
    }
  };

  const columns = [
    { field: "_id", headerName: "S.No", width: 80 },
    {
      field: "imageUrl",
      headerName: "Image",
      sortable: false,
      width: 250,
      editable: false,
      renderCell: (rowData) => {
        return (
          <div>
            {rowData?.row?.imageUrl ? (
              <HoverComponent props={rowData?.row?.imageUrl} />
            ) : (
              <Avatar />
            )}
          </div>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      sortable: false,
      width: 250,
      editable: false,
      renderCell: (rowData) => {
        return <div>{rowData?.row?.name ? rowData?.row?.name : "--"}</div>;
      },
    },
    {
      field: "email",
      headerName: "Email",
      sortable: false,
      width: 250,
      editable: false,
      renderCell: (rowData) => {
        return <div>{rowData?.row?.email ? rowData?.row?.email : "--"}</div>;
      },
    },
    {
      field: "phone",
      headerName: "Phone",
      sortable: false,
      width: 250,
      editable: false,
      renderCell: (rowData) => {
        return <div>{rowData?.row?.phone ? rowData?.row?.phone : "--"}</div>;
      },
    },
    {
      field: "whatsapp",
      headerName: "WhatsApp",
      sortable: false,
      width: 120,
      editable: false,
      renderCell: (rowData) => {
        const phone = rowData?.row?.phone;
        return (
          <div>
            {phone ? (
              <WhatsAppIcon
                sx={{ cursor: "pointer", color: "#25D366" }}
                onClick={() => handleClick(phone)}
              />
            ) : (
              "--"
            )}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 100,

      renderCell: (rowData) => {
        return (
          <div>
            <Button
              className="action-button"
              style={{ pointerEvents: "auto" }}
              onClick={() => handleAction(rowData?.row.id)}
            >
              {<EditIcon sx={{ color: "black" }} />}
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="m-container" style={{ margin: "auto" }}>
        <Header category="Page" title="Sellers" />
        <DataGridTable
          height="100%"
          width="100%"
          textAlign="center"
          data={rows || []}
          columns={columns}
          pageSize={pageSize}
          rowCount={totalRows}
          onPageChange={handlePageChange}
        />
    </div>
  );
};

export default Seller;
