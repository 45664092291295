// import * as React from 'react';
// import Timeline from '@mui/lab/Timeline';
// import TimelineItem from '@mui/lab/TimelineItem';
// import TimelineSeparator from '@mui/lab/TimelineSeparator';
// import TimelineConnector from '@mui/lab/TimelineConnector';
// import TimelineContent from '@mui/lab/TimelineContent';
// import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
// import TimelineDot from '@mui/lab/TimelineDot';
// import Typography from '@mui/material/Typography';
// import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
// import LocalShippingIcon from '@mui/icons-material/LocalShipping';
// import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
// import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
// import { Box } from '@mui/system';
// import Img from "./images/images (1).jpeg";
// import '../pages/timeline.css';
// export default function CustomizedTimeline() {
//   return (
//     <>
//       <Box sx={{ borderRadius: '10px', margin: '30px' }}>
//         <h1 className='mainHeading'>Arriving Today</h1>
//         <Box
//           component="img"
//           sx={{
//             height: 240,
//             width: 400,
//             maxHeight: { xs: 233, md: 167 },
//             maxWidth: { xs: 350, md: 250 },
//             marginLeft: '40%',
//           }}
//           src={Img}
//         />
//         <br />
//         <hr />
//         <h2 className='heading5'>Ordered</h2>
//         <br />
//         <br />
//         <Timeline position="alternate">
//           <TimelineItem>
//             <TimelineOppositeContent
//               sx={{ m: 'auto 0' }}
//               align="right"
//               color="text.secondary"
//             >
//             </TimelineOppositeContent>
//             <TimelineSeparator>
//               <TimelineConnector />
//               <TimelineDot>
//                 <ShoppingBagIcon />
//               </TimelineDot>
//               <TimelineConnector />
//             </TimelineSeparator>
//             <TimelineContent sx={{ py: '12px', px: 2 }}>
//               <Typography variant="h6" component="span">
//                 Ordered
//               </Typography>
//             </TimelineContent>
//           </TimelineItem>
//           <TimelineItem>
//             <TimelineSeparator>
//               <TimelineConnector />
//               <TimelineDot color="primary">
//                 <LocalShippingIcon />
//               </TimelineDot>
//               <TimelineConnector />
//             </TimelineSeparator>
//             <TimelineContent sx={{ py: '12px', px: 2 }}>
//               <Typography variant="h6" component="span">
//                 Shipped
//               </Typography>
//             </TimelineContent>
//           </TimelineItem>
//           <TimelineItem>
//             <TimelineSeparator>
//               <TimelineConnector />
//               <TimelineDot color="primary" variant="outlined">
//                 <DepartureBoardIcon />
//               </TimelineDot>
//               <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
//             </TimelineSeparator>
//             <TimelineContent sx={{ py: '12px', px: 2 }}>
//               <Typography variant="h6" component="span">
//                 Out for Delivery
//               </Typography>
//             </TimelineContent>
//           </TimelineItem>
//           <TimelineItem>
//             <TimelineSeparator>
//               <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
//               <TimelineDot color="secondary" variant="outlined">
//                 <DeliveryDiningIcon />
//               </TimelineDot>
//               <TimelineConnector />
//             </TimelineSeparator>
//             <TimelineContent sx={{ py: '12px', px: 2 }}>
//               <Typography variant="h6" component="span">
//                 Delivered
//               </Typography>
//             </TimelineContent>
//           </TimelineItem>
//         </Timeline>
//         <br />
//         <hr />
//         <br />
//         <Box sx={{ display: 'flex', margin: '50px' }}>
//           <Box sx={{ width: '300px', height: '150px', border: '4px solid gray', margin: '10px', textAlign: 'left', padding: '10px' }}> Delivery Info</Box>
//           <Box sx={{ width: '400px', height: '150px', border: '4px solid gray', margin: '10px', textAlign: 'left', padding: '10px' }}> Shipping Address</Box>
//           <Box sx={{ width: '300px', height: '150px', border: '4px solid gray', margin: '10px', textAlign: 'left', padding: '10px' }}>Order Info</Box>
//         </Box>
//       </Box>
//     </>
//   );
// }
import { Grid, Typography } from "@mui/material";
import { Box, display } from "@mui/system";
import React from "react";
import Avt3 from "../data/avatar3.png"
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import InputIcon from '@mui/icons-material/Input';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
const Timeline = () => {
  return (
    <Grid >
      <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Box sx={{ width: "200px", height: "200px", borderRadius: "20px" }}>
          <img style={{ borderRadius: "20px" }} src={Avt3}></img>
        </Box>
      </Grid>
      <div>
        <Grid xs={12} sm={12} sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>

          <CheckCircleIcon sx={{ color: "green" }} />
          <div style={{ width: "150px", height: "2px", border: "1px solid green" }}></div>

          <CheckCircleIcon sx={{ color: "green" }} />
          <div style={{ width: "150px", height: "2px", border: "1px solid green" }}></div>


          <CheckCircleIcon sx={{ color: "gray" }} />
          <div style={{ width: "150px", height: "2px", border: "1px solid grey" }}></div>

          <CheckCircleIcon sx={{ color: "gray" }} />
          <div style={{ width: "150px", height: "2px", border: "1px solid grey" }}></div>

        </Grid>
      </div>
      <Box sx={{ background: "none", padding: "20px" }}>
        <Grid container sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
          <Grid sx={{ display: "flex", gap: "10px" }} xs={12} sm={2}>
            <ShoppingBasketIcon />
            <Typography>Order Proceed</Typography>
          </Grid>
          <Grid sx={{ display: "flex", gap: "10px" }} xs={12} sm={2}>
            <ShoppingBasketIcon />
            <Typography>Order Shipped</Typography>
          </Grid>
          <Grid sx={{ display: "flex", gap: "10px" }} xs={12} sm={2}>
            <ShoppingBasketIcon />
            <Typography>Order In Route</Typography>
          </Grid>
          <Grid sx={{ display: "flex", gap: "10px" }} xs={12} sm={2}>
            <ShoppingBasketIcon />
            <Typography>Order Arrived</Typography>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )

}
export default Timeline;
