import React, { useEffect, useState } from "react"
import DataGridTable from "../components/DataGrid/DataGridTable";
import { callApi } from "../callApi/api";
import { useLocation, useParams } from 'react-router-dom';
import { Header } from "../components";
import { Grid, Popover , Menu, MenuItem, Button} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ParticularOrders = () => {
  const [pageSize, setPageSize] = useState(5)
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(0);
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const paramName = queryParams.get("userId")
  const[statusChange, setStatusChange] = useState(false)
  const [nameofUser, setNameOfUser] = useState("")

  const [rows, setRows] = useState([])

  const getData = async () => {
    const response = await callApi("get", `/admin/getOrder?userId=${paramName}&pageNumber=${page}&pageSize=${pageSize}`)
    setNameOfUser(response?.data?.data?.rows[0]?.user?.userProfile?.name)
    const modifiedData = response?.data?.data?.rows.map((prevData, index) => ({
      ...prevData,
      _id: index
    }))
    setRows(modifiedData)
    setTotalRows(response?.data?.data?.count)
  }

  useEffect(() => {
    getData()
  }, [page, pageSize, statusChange])

  const handlePageChange = (newPage) => {
    setPage(newPage.page);
    setPageSize(newPage.pageSize)
  };

  const DropDownButton = (props) => {

    const { rowData } = props;

    const dropDownStatus = {
        0: {
            backgroundColor: "#F4EE9F",
            buttonText: 'Pending',
            buttonTextColor: '#B51918',
        },
        1: {
            backgroundColor: "#F49F9F",
            buttonText: 'Confirmed',
            buttonTextColor: '#316719',
        },
        2: {
            backgroundColor: "#F49F9F",
            buttonText: 'Cancel',
            buttonTextColor: '#316719',
        },
        3: {
            backgroundColor: "#F49F9F",
            buttonText: 'Processed',
            buttonTextColor: '#316719',
        },
        4: {
            backgroundColor: "#F49F9F",
            buttonText: 'Dispatched',
            buttonTextColor: '#316719',
        },
        5: {
            backgroundColor: "#F49F9F",
            buttonText: 'In Transit',
            buttonTextColor: '#316719',
        },
        6: {
            backgroundColor: "#F49F9F",
            buttonText: 'Out for Delivery',
            buttonTextColor: '#316719',
        },
        7: {
            backgroundColor: "#F49F9F",
            buttonText: 'Delivered',
            buttonTextColor: '#316719',
        },
        8: {
            backgroundColor: "#F49F9F",
            buttonText: 'Attempted Delivery',
            buttonTextColor: '#316719',
        },
        9: {
            backgroundColor: "#F49F9F",
            buttonText: 'Delivery Rescheduled',
            buttonTextColor: '#316719',
        },
        10: {
            backgroundColor: "#F49F9F",
            buttonText: 'Returned',
            buttonTextColor: '#316719',
        }
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const [buttonColor, setButtonColor] = useState("");
    const [buttonTextColor, setButtonTextColor] = useState("");
    const [buttonText, setButtonText] = useState("");

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        if (rowData?.row?.status == 0) {
            setButtonColor("#F4EE9F");
            setButtonTextColor("#343D52");
            setButtonText("Pending");
        }
        if (rowData?.row?.status == 1) {
            setButtonColor("#FF7F50");
            setButtonTextColor("#343D52");
            setButtonText("Confirmed");
        }
        if (rowData?.row?.status == 2) {
            setButtonColor("#DE502A");
            setButtonTextColor("#343D52");
            setButtonText("Cancel");
        }
        if (rowData?.row?.status == 3) {
            setButtonColor("#FAD7A0 ");
            setButtonTextColor("#343D52");
            setButtonText("Processed");
        }
        if (rowData?.row?.status == 4) {
            setButtonColor("#9FE2BF");
            setButtonTextColor("#343D52");
            setButtonText("Dispatched");
        }
        if (rowData?.row?.status == 5) {
            setButtonColor("#2ABDDE");
            setButtonTextColor("#343D52");
            setButtonText("In Transit");
        }
        if (rowData?.row?.status == 6) {
            setButtonColor("#17A2B8");
            setButtonTextColor("#343D52");
            setButtonText("Out for Delivery");
        }
        if (rowData?.row?.status == 7) {
            setButtonColor("#27AE60");
            setButtonTextColor("#343D52");
            setButtonText("Delivered");
        }
        if (rowData?.row?.status == 8) {
            setButtonColor("#FFA500");
            setButtonTextColor("#343D52");
            setButtonText("Attempted Delivery");
        }
        if (rowData?.row?.status == 9) {
            setButtonColor("#F49F9F");
            setButtonTextColor("#343D52");
            setButtonText("Delivery Rescheduled");
        }
        if (rowData?.row?.status == 10) {
            setButtonColor("#BDC3C7");
            setButtonTextColor("#343D52");
            setButtonText("Returned");
        }

    }, []);

    const handleClose = async (value) => {
        const id = rowData?.row?.id;
        const formData = {
            id : id,
            status: value
        }
        const response = await callApi("post" , `admin/updateOrder`, formData)
        setAnchorEl(null);
        if (response.status) {
            setStatusChange(!statusChange)
            setButtonColor(dropDownStatus[value].backgroundColor)
            setButtonTextColor(dropDownStatus[value].buttonTextColor)
            setButtonText(dropDownStatus[value].buttonText)
        }
    };


    const MenuHandleClose = () => {
        setAnchorEl(null);
    };

    const menuStyle = {
        background: `${buttonColor}`, // Set your desired background color
        color: `${buttonTextColor}`, // Set your desired text color
        pointerEvents: 'auto',
    };

    const buttonStyle = {
        pointerEvents: 'auto', // Allow interactions with the button
    };

    return (
        <div>
            <Button
                sx={{
                    ...buttonStyle,
                    width: "fit-content",
                    height: "35px",
                    color: `${buttonTextColor}`,
                    borderColor: "white",
                    backgroundColor: `${buttonColor}`,
                    borderRadius: "0.5rem",
                    "&:hover": {
                        borderColor: "white",
                        backgroundColor: `${buttonColor}`,
                    },
                }}
                aria-controls="dropdown-menu"
                aria-haspopup="true"
                onClick={handleClick}
                variant="contained"
                color="primary"
            >
                {buttonText}{" "}
                <KeyboardArrowDownIcon sx={{ color: `${buttonTextColor}` }} />
            </Button>
            <Menu
                id="dropdown-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={MenuHandleClose}
                PaperProps={{ style: menuStyle }}
            >

                <MenuItem
                    value="0"
                    onClick={() => {
                        handleClose(0);
                    }}
                >
                    Pending
                </MenuItem>
                <MenuItem
                    value="1"
                    onClick={() => {
                        handleClose(1);
                    }}
                >
                    Confirmed
                </MenuItem>
                <MenuItem
                    value="2"
                    onClick={() => {
                        handleClose(2);
                    }}
                >
                   Cancel
                </MenuItem>
                <MenuItem
                    value="3"
                    onClick={() => {
                        handleClose(3);
                    }}
                >
                   Processed
                </MenuItem>
                <MenuItem
                    value="4"
                    onClick={() => {
                        handleClose(4);
                    }}
                >
                    Dispatched
                </MenuItem>
                <MenuItem
                    value="5"
                    onClick={() => {
                        handleClose(5);
                    }}
                >
                    In Trasit
                </MenuItem>
                <MenuItem
                    value="6"
                    onClick={() => {
                        handleClose(6);
                    }}
                >
                    Out for Delivery
                </MenuItem>
                <MenuItem
                    value="7"
                    onClick={() => {
                        handleClose(7);
                    }}
                >
                    Delivered
                </MenuItem>
                <MenuItem
                    value="8"
                    onClick={() => {
                        handleClose(8);
                    }}
                >
                   Attempted Delivery
                </MenuItem>
                <MenuItem
                    value="9"
                    onClick={() => {
                        handleClose(9);
                    }}
                >
                  Delivery Rescheduled
                </MenuItem>
                <MenuItem
                    value="10"
                    onClick={() => {
                        handleClose(10);
                    }}
                >
                  Returned
                </MenuItem>

            </Menu>
        </div>
    );
};

  const HoverComponent = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    let [open, setOpen] = React.useState(Boolean)

    const { rowData } = props;

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
      setOpen(true)
    };


    const handleClose = () => {
      setAnchorEl(null);
      setOpen(false)
    };

    const id = open ? 'simple-popover' : undefined;

    return (
      <>
        <div onMouseEnter={handleClick} style={{ display: "flex", alignItems: "center", height: "100%", cursor: "pointer", pointerEvents: "auto", width: "60px" }}>
          <div onMouseLeave={handleClose} style={{ gap: "20px", display: "flex", cursor: "pointer", pointerEvents: "auto", }}>
            <img style={{ width: "50px", height: "35px" }} src={rowData?.row?.product?.productDocs[0]?.url}></img>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              // onMouseLeave={handleClose} 
              anchorOrigin={{
                // vertical: 'left',
                // horizontal: 'right',
              }}
              slotProps={{ paper: { onMouseLeave: handleClose } }}
            >
              <img style={{ width: "300px", height: "300px", cursor: "pointer" }} src={rowData?.row?.product?.productDocs[0]?.url}></img>

            </Popover>
          </div>

        </div>
      </>
    )
  }

  const columns = [
    {
      field: 'ooa',
      headerName: 'Product Image',
      sortable: false,
      width: 150,
      editable: false,
      renderCell: (rowData) => {
        return (
          <HoverComponent rowData={rowData} />
        );
      },
    },
    {
      field: 'kk',
      headerName: 'Total Price',
      sortable: false,
      width: 200,
      editable: false,
      renderCell: (rowData) => {
        return (
          <div>
            {rowData?.row?.totalPrice ? rowData?.row?.totalPrice : "--"}
          </div>
        );
      },
    },
    {
      field: 'op',
      headerName: 'Quantity',
      sortable: false,
      width: 120,
      editable: false,
      renderCell: (rowData) => {
        return (
          <div>
            {rowData?.row?.quantity ? rowData?.row?.quantity : "--"}
          </div>
        );
      },
    },
    {
      field: 'hkle',
      headerName: 'Tax',
      sortable: false,
      width: 120,
      editable: false,
      renderCell: (rowData) => {
        return (
          <div>
            {rowData?.row?.tax >= 0 ? rowData?.row?.tax : "--"}
          </div>
        );
      },
    },
    {
      field: 'jj',
      headerName: 'Shipping Charge',
      sortable: false,
      width: 200,
      editable: false,
      renderCell: (rowData) => {
        return (
          <div>
            {rowData?.row?.shippingCharge ? rowData?.row?.shippingCharge : "--"}
          </div>
        );
      },
    },
    {
      field: 'qald',
      headerName: 'Status',
      sortable: false,
      width: 250,
      editable: false,
      renderCell: (rowData) => {
        return (
          <div>
            <DropDownButton rowData={rowData}/>
          </div>
        );
      },
    },
  ];


  return (
    <>
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
        <div style={{display:"flex", gap:"20px"}}>
          <ArrowBackIcon onClick={() => window.history.back()} sx={{cursor:"pointer"}}/>
        <Header title={` ${rows.length < 1 ? "No Order Found" : "Particular Orders"} ${nameofUser != undefined ? "of" : ""} ${nameofUser != undefined ? nameofUser : ""}`} />
        </div>

        <DataGridTable
          height="100%"
          width="100%"
          textAlign='center'
          data={rows || []}
          columns={columns}
          pageSize={pageSize}
          rowCount={totalRows}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  )
}

export default ParticularOrders;