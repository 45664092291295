import React, { useEffect, useState, useRef } from "react";
import Typography from "@mui/material/Typography";
import { Button, Grid } from "@mui/material";
import Person from "@mui/icons-material/Person";
import DeleteIcon from '@mui/icons-material/Delete';
import { Upload } from "@mui/icons-material";
const UploadCompForSellerDetails = (props) => {
    const { use, bro, file, handleImageChange, name, sx, label, buttonText, textSize, xs, handleDelete, index, docSubmit, handleClick } =
        props;
    const [lastSegment, setLastSegment] = useState(null);
    const [filePreview, setFilePreview] = useState(null);
    const fileInputRef = useRef(null);
    function isUrl(input) {
        const urlPattern = /^(http|https):\/\/[^ "]+$/;
        return typeof input === 'string' && urlPattern.test(input);
    }

    useEffect(() => {
        var flag = false;
        if (file && !flag) {
            if (file.name) {
                const segments = file.name && file.name.split('.');
                const extension = segments[segments.length - 1];
                setLastSegment(extension);
                const reader = new FileReader();
                reader.onloadend = () => {
                    setFilePreview(reader.result);
                };
                reader.readAsDataURL(file);
                flag = true;
            }else if(isUrl(file)){
                setFilePreview(file)
            }
        }
        else {
            setFilePreview(null);
            setLastSegment(null);
        }
    }, [file]);

    const allowedExtensions = ["png", "jpeg", "jpg", "svg"];

    const text = buttonText ? buttonText : "Upload";
    const buttonSx = sx
        ? sx
        : {
            width: "12rem",
            border: "1px dashed grey",
            height: "2.5rem",
            textTransform: "none",
            "&:hover": {
                backgroundColor: `black`,
            },
        };
        console.log(filePreview,'=============================')

    return (
        <Grid
            sx={{
                minHeight: "100px",
                maxHeight: "200px",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                margin: "auto"
            }}
            item
            xs={xs ? xs : 12}
        >
            <Typography
                sx={{
                    color: "grey",
                    fontSize: "11px",
                    height: "10px",
                    marginBottom: "10px",
                }}
                variant="subtitle2"
            >
                {label}
            </Typography>
            {filePreview ? (
                <div style={{}} className="d-flex justify-content-center align-items-center">

                    {allowedExtensions.includes(lastSegment) ?
                        <img
                            src={filePreview}
                            alt="File Preview"
                            style={{ width: "120px", height: "120px", margin: "auto", marginBottom: "10px" }}
                        /> :
                        <>
                        <img
                            src={filePreview}
                            alt="File Preview"
                            style={{ width: "120px", height: "120px", margin: "auto", marginBottom: "10px" }}
                        />
                        </>
                    }
                    <Button sx={buttonSx} variant="contained" component="span">

                        <DeleteIcon sx={{ bottom: "0px", cursor: "pointer" }} onClick={() => docSubmit ? handleDelete(fileInputRef, index) : handleDelete(index, fileInputRef)} className="text-danger" />
                    </Button>
                </div>
            ) : (
                <>
                    <div style={{ width: "120px", height: "120px", margin: "auto", marginBottom: "10px" }}>
                        <Person style={{ width: "100%", height: "100%", border: "1px dotted gray" }} />
                    </div>
                    <label htmlFor={name} style={{ width: "100%" }}>

                        <Button sx={buttonSx} variant="contained" component="span">
                            <Upload style={{ fontSize: "17px" }} />
                            &nbsp;
                            <span
                                style={{ fontSize: textSize ? textSize : "10px", color: "black", padding: "0px", margin: "0px" }}
                            >{`${text}`}</span>
                        </Button>
                    </label>
                </>

            )}
            <input
                type="file"
                ref={fileInputRef}
                id={name}
                accept=".pdf, .mp4, .avi, .mov, video/*, image/*"
                style={{ display: "none" }}
                name={name}
                onChange={(e) => { handleImageChange(e, index) }}
            />


            {bro == 1 && index > 0 ? (
                <div className="d-flex justify-content-between align-items-center">
                    <DeleteIcon sx={{ cursor: "pointer" }} onClick={() => handleClick(name)} />
                </div>
            ) : bro === undefined && index != 0 && use != 2 ? (
                <div className="d-flex justify-content-between align-items-center">
                    <DeleteIcon sx={{ cursor: "pointer" }} onClick={() => handleClick(name)} />
                </div>
            ) : (
                <></>
            )
            }
        </Grid>
    );
};

export default UploadCompForSellerDetails;

