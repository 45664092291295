import { Grid, TextField, Typography, Box, Button, Select, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomizedSnackbars from "../Snackbar";
import ToggleButton from "../../ToggleButton.js";
import { callApi } from "../../callApi/api.js";

const TopDealsProduct = () => {
    const [products, setProducts] = useState([
        {
            isActive: false,
            topic: "",
            productIds: [{ id: "" }]
        }
    ]);

    const [productData, setProductData] = useState([])

    const [snackBarMsg, setSnackBarMsg] = React.useState({
        message: "",
        severity: "success",
        open: false,
    });

    const handleInputChange = (event, productIndex, productIdIndex) => {
        const { name, value } = event.target;

        if (name === "topic") {
            setProducts((prevProducts) => prevProducts.map((product, index) =>
                index === productIndex ? { ...product, topic: value } : product
            ));
        } else if (name === "productId") {
            setProducts((prevProducts) => {
                if (prevProducts[productIndex].productIds.some((id) => id.id === value)) {
                    setSnackBarMsg({
                        message: "This product has already been added to the deal. Please select a different product.",
                        severity: "error",
                        open: true
                    });
                    return prevProducts;
                }
                return prevProducts.map((product, index) =>
                    index === productIndex ? {
                        ...product,
                        productIds: product.productIds.map((productId, idx) =>
                            idx === productIdIndex ? { id: value } : productId
                        )
                    } : product
                );
            });
        }
    };

    const getData = async () => {
        const response = await callApi("get", "admin/products")
        setProductData(response?.data?.data?.data)
    }

    useEffect(() => {
        getData()
    }, [])

    const handleAddProduct = () => {
        setProducts((prevProducts) => [
            ...prevProducts,
            { isActive: false, topic: "", productIds: [{ id: "" }] }
        ]);
    };

    const handleAddProductId = (productIndex) => {
        setProducts((prevProducts) => prevProducts.map((product, index) =>
            index === productIndex ? {
                ...product,
                productIds: [...product.productIds, { id: "" }]
            } : product
        ));
    };

    const handleRemoveProductId = (productIndex, productIdIndex) => {
        let newArray = [...products]
        newArray[productIndex].productIds.splice(productIdIndex, 1)
        setProducts(newArray)
    };

    const handleRemoveProduct = (productIndex) => {
        setProducts((prevProducts) => prevProducts.filter((_, index) => index !== productIndex));
    };
    const handleSave = async () => {
        let id = false
        let title = false

        for (let key of products) {
            if (!key.topic) {
                title = true
                break;
            }
            for (let data of key.productIds) {
                if (!data.id) {
                    id = true
                    break;
                }
            }
        }
        if (title || id) {
            setSnackBarMsg({
                message: "Please fill all the fields",
                severity: "error",
                open: true
            })
            return
        }
        let array = [
            ...products,

        ]
        const transformedDeals = products.map(product => ({ topic: product.topic, productIds: product.productIds.map(p => p.id), isActive: product.isActive }));
        let obj = {
            deals: [...transformedDeals]
        }
        const response = await callApi("post", "admin/landing-page-deals", obj)
        if (response) {
            window.location.href = "/bestDeals"
        }
    }

    const handleIsActiveChange = (index) => {
        let newArray = [...products]
        newArray[index].isActive = !products[index].isActive
        setProducts(newArray)
    }

    return (
        <>
            <div style={{ padding: "20px", display: 'flex', gap: '20px', alignItems: 'center', justifyContent: 'center', }}>
                <div className="pro containers d-flex flex-column justify-content-center align-items-start rounded" style={{ width: "90%", height: '100%', boxShadow: 'rgb(113 122 131 / 11%) 0px 7px 30px 0px', padding: "30px" }}>
                    <Typography sx={{ fontSize: "20px", fontWeight: "500", padding: "10px" }}>Create Deals for Landing Page</Typography>
                    {products.map((product, productIndex) => (
                        <Card key={productIndex} sx={{ marginBottom: 2, width: "100%" }}>
                            <CardContent>
                                <Grid item xs={12}>
                                    <ToggleButton isActive={products[productIndex].isActive} index={productIndex} handleChange={handleIsActiveChange} label={products[productIndex].isActive ? "Active" : "Inactive"} />
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography>Title</Typography>
                                        <TextField
                                            name="topic"
                                            value={product?.topic}
                                            onChange={(event) => handleInputChange(event, productIndex)}
                                            fullWidth
                                        />
                                    </Grid>
                                    {product?.productIds.map((productId, productIdIndex) => (
                                        <Grid item xs={12} key={productIdIndex}>
                                            <Typography>Product ID</Typography>
                                            <Select
                                                name="productId"
                                                value={products[productIndex].productIds[productIdIndex].id}
                                                onChange={(event) => handleInputChange(event, productIndex, productIdIndex)}
                                                fullWidth
                                                inputProps={{ style: { display: 'none' } }}
                                                MenuProps={{
                                                    PaperProps: {
                                                      style: {
                                                        height:"40%",
                                                        width:"63%",
                                                        minWidth: '200px',
                                                        top: '79px',
                                                        bottom: "200px",
                                                        left: '719px',
                                                        transform: 'none',
                                                        opacity: 1,
                                                        transformOrigin: '190px 21.8767px',
                                                        borderRadius: "0.5rem",
                                                        transition: 'opacity 295ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 196ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                                      },
                                                    },
                                                  }}
                                            >
                                                {productData && productData.map((option, index) => {
                                                    return (
                                                        <MenuItem key={index} value={option.id}>
                                                            {option.name}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                            {productIdIndex > 0 && (
                                                <Button
                                                    onClick={() => handleRemoveProductId(productIndex, productIdIndex)}
                                                    variant="contained"
                                                    color="secondary"
                                                    startIcon={<DeleteIcon />}
                                                    sx={{ mt: 1 }}
                                                >
                                                    Remove Product ID
                                                </Button>
                                            )}
                                        </Grid>
                                    ))}
                                    <Grid item xs={12}>
                                        <Button
                                            onClick={() => handleAddProductId(productIndex)}
                                            variant="contained"
                                            sx={{ mt: 2 }}
                                        >
                                            + Add More Product ID
                                        </Button>
                                    </Grid>
                                    {productIndex > 0 && (
                                        <Grid item xs={12}>
                                            <Button
                                                onClick={() => handleRemoveProduct(productIndex)}
                                                variant="contained"
                                                color="secondary"
                                                startIcon={<DeleteIcon />}
                                                sx={{ mt: 2 }}
                                            >
                                                Remove Deal
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            </CardContent>
                        </Card>
                    ))}
                    <Box sx={{ paddingTop: '20px', width: "100%", gap: "30px", display: "flex" }}>
                        <Button
                            onClick={handleAddProduct}
                            variant="contained"
                        >
                            + Add More Deal
                        </Button>
                        <Button
                            onClick={handleSave}
                            variant="contained"
                            color="primary"
                        >
                            Save Deal
                        </Button>
                    </Box>
                </div>
            </div>
            <CustomizedSnackbars msgData={snackBarMsg} setMsgData={setSnackBarMsg} />
        </>
    );
};

export default TopDealsProduct;
