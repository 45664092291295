import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Button } from "@mui/material";
import "../All-products/Styles/AllProduct.css"
import EditIcon from '@mui/icons-material/Edit';
import Header from '../Header';
import { callApi } from '../../callApi/api';
import ProductModal from '../All-products/productModal';
import DataGridTable from '../DataGrid/DataGridTable';
import { useParams } from 'react-router-dom';
import AddInventory from './AddInventory';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const InventoryLogs = (props) => {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const { productId } = useParams();

  const handlePageChange = (newPage) => {
    setPage(newPage.page);
    setPageSize(newPage.pageSize)
  };

  function convertToLocalTime(isoDateString) {
    // Parse the ISO date string
    const date = new Date(isoDateString);
    
    // Create an Intl.DateTimeFormat object for IST (UTC+5:30)
    const options = {
      timeZone: 'Asia/Kolkata',
      hour12: true,
      hour: 'numeric',
      minute: 'numeric'
    };
    const formatter = new Intl.DateTimeFormat('en-US', options);
    
    // Format the date to the desired string format
    const localTimeString = formatter.format(date);
  
    return localTimeString;
  }

  function formatDate(inputDate) {
    // Array of month names
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    // Split the input date into parts
    const [year, month, day] = inputDate.split('-');

    // Convert month from "01", "02", etc. to "January", "February", etc.
    const monthName = monthNames[parseInt(month, 10) - 1];

    // Construct and return the formatted date string
    return `${parseInt(day, 10)} ${monthName} ${year}`;
}

  const getApi = async () => {
    try {
      let counting = page * pageSize + 1
      const response = await callApi("get", `/admin/getInventory?productId=${productId}&pageSize=${pageSize}&pageNumber=${page}`)
      const modifiedData = response?.data?.data?.rows?.map((data, index) => ({
        ...data,
        _id: counting + index
      }))
      setRows(modifiedData)
      setTotalRows(response?.data?.data?.count)
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getApi()
  }, [page, pageSize])
  const columns = [
    { field: '_id', headerName: 'S.No', width: 300 },
    {
      field: 'quantity',
      headerName: 'Quantity',
      sortable: false,
      width: 200,
      renderCell: (rowData) => {
        return (
          <div>
            {rowData?.row?.quantity}
          </div>
        );
      },
    },

    {
      field: 'qaBy',
      headerName: 'Q&A By',
      sortable: false,
      width: 300,
      editable: true,
      renderCell: (rowData) => {
        return (
          <div>
            {rowData?.row?.qaBy}
          </div>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      sortable: false,
      width: 200,
      renderCell: (rowData) => {
        return (
          <div>
            {`${formatDate(rowData?.row?.createdAt.split("T")[0])} at ${convertToLocalTime(rowData?.row?.createdAt)}`}
          </div>
        );
      },
    },
  
  ];

  const handleRowClick = (event) => {
    // setSelectedProductId(event.row.id)
    // setOpen(true)

  };
  const [open, setOpen] = useState(false);
  // const [selectedProductId, setSelectedProductId] = useState(null);

const handleClose = () => {
  setOpen(false);
}

  return (
    <>
      <div>
        <div className="m-container" style={{ margin: 'auto' }}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            <Box sx={{display:"flex", gap:"20px",}}>
            <ArrowBackIcon onClick={() => window.history.back()} sx={{fontSize:"30px", cursor:"pointer"}}/>
            <Header title="InventoryLogs" />
            </Box>
            <Button style={{ color: "black", border: "1px solid black", borderRadius: '4px', height: "40px" }}  onClick={()=>setOpen(true)} >Add Inventory</Button>
          </Box>

          <Box sx={{ overflow: "auto" }}>
            <DataGridTable
              onCellEditStop={(params, event) => {
                // response = callApi('/put', `/admin/inventory`)
                
              }}
              handleRowClick={handleRowClick}
              height="80%"
              width="100%"
              textAlign='center'
              data={rows || []}
              columns={columns}
              pageSize={pageSize}
              rowCount={totalRows}
              onPageChange={handlePageChange}
            />
          </Box>
        </div>
      </div>
      <AddInventory open={open} handleClose={handleClose} id={productId}/>
    </>
  );
};

export default InventoryLogs;
