// App.js
import React from 'react';
import MainMenu from './components/menu/mainMenu'
import MainPage from './mainLogin';
import LoginPage from './loginPage';
import Blank from './blank';
import CustomizedTimeline from './pages/timeline';
import AdminProfile from './components/Profile/AdminProfile';
import EditProfile from './components/Profile/EditProfile';
import Product from './components/All-products/Product';
import AllProducts from './components/All-products/AllProducts';
import Banner from './pages/Banner/Banner';
import Slider from './pages/Slider/Slider';
import GridImage from './pages/GridImage/GridImage';

import Blogs from './pages/Blogs/Blogs';
import UserManagement from './pages/usermanagement/usermanagement';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import ParticularOrders from './pages/ParticularOrders';
import ParticularStatusLogs from './pages/ParticularStatusLogs';
import MultiLevelDropdown from "./components/All-products/MultiLevelDropdown";
import UserLandingPage from "./components/LandingPage/UserLandingPage";
import BestDeals from './components/LandingPage/BestDeals';
import {
  Ecommerce,
  Orders,
  Calendar,
  Employees,
  Stacked,
  Pyramid,
  Customers,
  Kanban,
  Line,
  Area,
  Bar,
  Pie,
  Financial,
  ColorPicker,
  ColorMapping,
  Editor,
  Seller,
} from "./pages";
import { useStateContext } from "./contexts/ContextProvider";
import Category from '../src/components/menu/mainMenu'
import Layout from './layout';
import EditAllProducts from './components/All-products/EditAllProduct';
import HeaderLayout from './HeaderLayout';
import Inventory from '../src/components/InventoryLogs/Inventory'
import InventoryLogs from './components/InventoryLogs/Inventorylogs';
import SellerDetails from './components/Seller/SellerDetails';

const App = () => {
  // const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode } =
  //   useStateContext();
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* dashboard  */}
          <Route path="/ecommerce" element={<Layout children={<Ecommerce />} />} />
          <Route path="/ecommerce" element={<Layout children={<Ecommerce />} />} />
          {/* pages  */}
          <Route path="/orders" element={<Layout children={<Orders />} />} />
          <Route path="/landingPage" element={<Layout children={<UserLandingPage />} />} />
          <Route path="/bestDeals" element={<Layout children={<BestDeals />} />} />
          <Route path="/employees" element={<Layout children={<Employees />} />} />
          <Route path="/inventory" element={<Layout children={<Inventory />} />} />
          <Route path="/particularOrders" element={<Layout children={<ParticularOrders />} />} />
          <Route path="/ParticularStatusLogs" element={<Layout children={<ParticularStatusLogs />} />} />
          <Route path="/inventory/:productId" element={<Layout children={ <InventoryLogs />} />} />
          {/* <Route path="/editALLInventory/:productId" element={<Layout children={ <EditInventoryLogs />} />} /> */}
          <Route path="/customers" element={<Layout children={<Customers />} />} />
          <Route path="/seller" element={<Layout children={<Seller />} />} />
          <Route path="/timeline" element={<Layout children={<CustomizedTimeline />} />} />
          <Route path="/AllProducts" element={<Layout children={<AllProducts />} />} />
          <Route path='/Banner' element={<Layout children={<Banner />} />} />
          {/* apps  */}
          <Route path="/kanban" element={<Layout children={<Kanban />} />} />
          <Route path="/editor" element={<Layout children={<Editor />} />} />
          <Route path="/calendar" element={<Layout children={<Calendar />} />} />
          <Route path="/color-picker" element={<Layout children={<ColorPicker />} />} />
          {/* charts  */}
          <Route path="/line" element={<Layout children={<Line />} />} />
          <Route path="/area" element={<Layout children={<Area />} />} />
          <Route path="/bar" element={<Layout children={<Bar />} />} />
          <Route path="/pie" element={<Layout children={<Pie />} />} />
          <Route path="/financial" element={<Layout children={<Financial />} />} />
          <Route path="/color-mapping" element={<Layout children={<ColorMapping />} />} />
          <Route path="/pyramid" element={<Layout children={<Pyramid />} />} />
          <Route path="/stacked" element={<Layout children={<Stacked />} />} />
          <Route path="/mainPage" element={<Layout children={<MainPage />} />} />
          <Route path="/" element={<HeaderLayout children={<LoginPage/>} />} />
          <Route path="/profile" element={<Layout children={<AdminProfile />} />} />
          <Route path="/editProfile" element={<Layout children={<EditProfile />} />} />
          <Route path="/products" element={<Layout children={<Product />} />} />
          <Route path="/addCategory" element={<Layout children={<MainMenu />} />} />
          <Route path="/usermanage" element={<Layout children={<UserManagement />} />} />
          <Route path="/multi" element={<Layout children={<MultiLevelDropdown />} />} />
          <Route path="/blank" element={<Blank />} />
          <Route path="/editALLProducts" element={<Layout children={<EditAllProducts />} />} />
          <Route path="/category" element={<Layout children={<Category />} />} />
          <Route path="/landingPage" element={<Layout children={<UserLandingPage />} />} />
          <Route path="/sellerDetails" element={<Layout><SellerDetails /></Layout>} />
          <Route path="/Slider" element={<Layout><Slider /></Layout>} />
          <Route path="/GridImage" element={<Layout><GridImage /></Layout>} />
          <Route path="/Blogs" element={<Layout><Blogs /></Layout>} />

        </Routes>
      </BrowserRouter>
    </>
  );
}


export default App;