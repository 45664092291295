import React, { useState, useEffect } from "react";
import { callApi } from "../callApi/api";
import { Header } from '../components';
import Box from '@mui/material/Box';
import { Grid } from "@mui/material";
import DataGridTable from "../components/DataGrid/DataGridTable";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Navigate, useNavigate } from "react-router-dom";

const Customers = () => {

  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const navigate = useNavigate()
  const [page, setPage] = useState(0);
  const handlePageChange = (newPage) => {
    setPage(newPage.page + 1);
    setPageSize(newPage.pageSize)
  };
  const getApi = async () => {
    try {
      let counting = page * pageSize + 1
      const response = await callApi("get", 'admin/getAllusers')
      const modifiedData = response?.data?.data?.data?.map((data, index) => ({
        ...data,
        _id: counting + index
      }))

      setRows(modifiedData)
      setTotalRows(response?.data?.data?.length)


    }
    catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getApi()
  }, [])

  const handleClick = (id) => {
    navigate(`/particularOrders?userId=${id}`)
  }

  const columns = [
    { field: '_id', headerName: 'S.No', width: 80 },
    {
      field: 'email',
      headerName: 'Email',
      sortable: false,
      width: 250,
      editable: false,
      renderCell: (rowData) => {
        return (
          <div>
            {rowData?.row?.email}
          </div>
        );
      },
    },
    {
      field: 'phone',
      headerName: 'Phone',
      sortable: false,
      width: 200,
      editable: false,
      renderCell: (rowData) => {
        return (
          <div>
            {rowData?.row?.phone ? rowData?.row?.phone : "--"}
          </div>
        );
      },
    },
    {
      field: 'a',
      headerName: 'View Orders',
      sortable: false,
      width: 120,
      editable: false,
      renderCell: (rowData) => {
        return (
          <div>
           <VisibilityIcon sx={{cursor:"pointer", pointerEvents:"auto"}} onClick={() => {handleClick(rowData?.row?.id)}}/>
          </div>
        );
      },
    },
  ];

  return (
    <div className="m-container" style={{ margin: "auto" }}>
      <Header category="Page" title="Customers" />
      <DataGridTable
        height="100%"
        width="100%"
        textAlign='center'
        data={rows || []}
        columns={columns}
        pageSize={pageSize}
        rowCount={totalRows}
        onPageChange={handlePageChange}
      />
    </div >
  )
};
export default Customers;
