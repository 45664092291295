import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
    Card, CardMedia, CardContent, CardActions, Button, Typography, Grid, CircularProgress, Alert, Container, IconButton
} from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { callApi } from '../../callApi/api';

const BestDeals = () => {
    const [deals, setDeals] = useState([]);
    const navigate = useNavigate()
    const [productData, setProductData] = useState([])
    const [hoveredCard, setHoveredCard] = useState(null);

    useEffect(async () => {
        const [landingResponse, productResponse] = await Promise.all([
            callApi('get', 'admin/getLandingPageDeals'),
            callApi('get', 'admin/products')
        ]);

        setDeals(landingResponse.data.data)
        setProductData(productResponse?.data?.data?.data)

    }, []);

    const extractTextFromHTML = (htmlString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        return doc.body.textContent || "";
    };

    const routeToOther = (id) => {
        navigate(`/landingPage?id=${id}`)
    }

    return (
        <Container style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: deals.length < 1 ? 'center' : 'flex-start', minHeight: '100vh'}}>
        {deals.length < 1 ? 
        <>
        <h1 style={{fontStyle:"italic", display:"flex", alignItems:"center", justifyContent:"center"}}>No Deals</h1>
        <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate("/landingPage")}
                    sx={{
                        borderRadius: '8px',
                        backgroundColor: '#1976d2',
                        '&:hover': {
                            backgroundColor: '#115293',
                        },
                        padding: '10px 20px',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
                        marginTop:"20px",
                        justifyContent:"center",
                        alignItems:"center",
                        display:"flex"
                    }}
                >
                    Create New Deal
                </Button>
                </>
        :
        <>
        <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate("/landingPage")}
                    sx={{
                        borderRadius: '8px',
                        backgroundColor: '#1976d2',
                        '&:hover': {
                            backgroundColor: '#115293',
                        },
                        padding: '10px 20px',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
                        marginBottom:"50px"
                    }}
                >
                    Create New Deal
                </Button>

        <Grid container spacing={4}>
            {deals?.map((deal) => (
                <Grid item xs={12} key={deal.id} sx={{ marginBottom: '20px'}}>
                    <Typography sx={{fontStyle:"italic"}} variant="h5" gutterBottom>
                        {deal.topic}
                    </Typography>
                    <Grid container spacing={2}>
                        {deal.productIds.map((d, index) => {
                            const product = productData.find(p => p.id === d);
                            if (product) {
                                return (
                                    <Grid item xs={12} sm={6} md={4} key={index}>
                                        <Card 
                                        onMouseEnter={() => setHoveredCard(product.id)}
                                        onMouseLeave={() => setHoveredCard(null)}
                                        onClick={() => routeToOther(product.id)} 
                                        sx={{ borderRadius: '16px', 
                                        cursor: "pointer", 
                                        height: '350px', 
                                        display: 'flex', 
                                        flexDirection: 'column', 
                                        justifyContent: 'space-between',
                                        boxShadow: hoveredCard === product.id ? '0 8px 16px rgba(0,0,0,0.2)' : '0 4px 8px rgba(0,0,0,0.1)',
                                        transform: hoveredCard === product.id ? 'scale(1.02)' : 'scale(1)',
                                        transition: 'transform 0.3s, box-shadow 0.3s'
                                        }}>
                                            <CardMedia
                                                component="img"
                                                alt={product.title}
                                                sx={{ height: '50%', objectFit: 'cover' }} // Adjust height here
                                                image={product?.productDocs[0]?.url}
                                                title={product.title}
                                            />
                                            <CardContent sx={{ padding: '8px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '50%' }}>
                                                <Typography gutterBottom variant="h6" component="div" sx={{ marginBottom: '4px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                    {product.title}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    {extractTextFromHTML(product.description).length > 150 ? extractTextFromHTML(product.description).slice(0,150) + "..." : extractTextFromHTML(product.description)}
                                                </Typography>
                                                <Typography variant="h6" color="primary" sx={{ marginTop: 'auto' }}>
                                                 ₹{product.price}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </Grid>
                </Grid>
            ))}
        </Grid>
        </>
        }
    </Container>
    );
};

export default BestDeals;
