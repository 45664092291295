import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../src/components/All-products/Styles/customQuill.css";
import { Box } from "@mui/material";

const CustomisedEditor = (props) => {
  const { handleChange, content } = props;
  const modules = {
    toolbar: {
      container: [
        [{ size: [] }],
        [
          "bold",
          "italic",
          "underline",
          { list: "ordered" },
          { list: "bullet" },
          "link",
          "image",
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
      ],
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        paddingBottom: "50px",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="reactQuillData"
    >
      <ReactQuill
        modules={modules}
        value={content}
        onChange={handleChange}
        className="custom-quill"
        name="editor"
      />
    </Box>
  );
};
export default CustomisedEditor;
