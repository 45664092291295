import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import { Button } from "@mui/material";
import '../All-products/Styles/AllProduct.css'
import { Header } from "..";
import { callApi } from "../../callApi/api";
import ProductModal from "../All-products/productModal";
// import DeleteIcon from '@mui/icons-material/Delete';
import DataGridTable from "../DataGrid/DataGridTable";
import { useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const Inventory = (props) => {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const navigate = useNavigate();
  const handleEdit = (id) => {
    navigate(`/inventory/${id}`);

  }
  const handlePageChange = (newPage) => {
    setPage(newPage.page);
    setPageSize(newPage.pageSize)
  };

  const getApi = async () => {
    try {
      let counting = page * pageSize + 1
      const response = await callApi("get", `admin/products?type=admin&pageNumber=${page}&pageSize=${pageSize}`)
      const modifiedData = response?.data?.data?.data?.map((data, index) => ({
        ...data,
        _id: counting + index
      }))

      setRows(modifiedData)
      setTotalRows(response?.data?.data?.count)
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getApi()
  }, [page, pageSize])
  const columns = [
    { field: '_id', headerName: 'S.No', width: 80 },
    {
      field: 'name',
      headerName: 'Name',
      sortable: false,

      width: 700,

      renderCell: (rowData) => {
        return (
          <div>
            {rowData?.row?.name > 35 ? rowData?.row?.name.slice(0, 35) + "..." : rowData?.row?.name}
          </div>
        );
      },
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      sortable: false,
      width: 100,
      renderCell: (rowData) => {
        return (
          <div>
            {rowData?.row?.quantity}
          </div>
        );
      },
    },
    {
      field: 'Inventory',
      headerName: 'Show Inventory',
      sortable: false,
      width: 150,
      renderCell: (rowData) => {
        return (
          <div>
            <Button className="action-button" style={{ pointerEvents: "auto" }} onClick={() => handleEdit(rowData?.row?.id)}> <RemoveRedEyeIcon sx={{ color: "black" }} /> </Button>
          </div>
        );
      },
    },

  ];

  const handleRowClick = (event) => {
    setSelectedProductId(event.row.id)
    setOpen(true)

  };
  const [open, setOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);


  return (
    <>
      <div>
        <div className="m-container" style={{ margin: 'auto' }}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "25px" }}>
            <Header title="Inventory" />
            {/* <Button style={{ color: "black", border: "1px solid black", borderRadius: '4px', height: "40px" }} onClick={handleEdit} >Add Inventory</Button> */}
          </Box>
          <Box sx={{ overflow: "auto" }}>

            {
              open ?

                <ProductModal productId={selectedProductId} open={open} setOpen={setOpen} setSelectedProductId={setSelectedProductId} />
                : <></>
            }
            <DataGridTable
              handleRowClick={handleRowClick}
              height="80%"
              width="100%"
              textAlign='center'
              data={rows || []}
              columns={columns}
              pageSize={pageSize}
              rowCount={totalRows}
              onPageChange={handlePageChange}
            />
          </Box>

        </div>
      </div>
    </>
  );
};

export default Inventory;
