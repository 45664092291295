import React, { useState, useEffect } from "react";
import { callApi } from "../../callApi/api";
import Box from '@mui/material/Box';
import { Button } from "@mui/material";
import "../All-products/Styles/AllProduct.css"
import EditIcon from '@mui/icons-material/Edit';
import DataGridTable from "../DataGrid/DataGridTable";
import { Header } from "..";
import ProductModal from "./productModal";

const handleEdit = () => {

  window.location.href = '/products';

}
const AllProducts = (props) => {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const handleAction = (id) => {
    window.location.href = `/editALLProducts?productId=${id}`;
  }
  const handlePageChange = (newPage) => {
    setPage(newPage.page);
    setPageSize(newPage.pageSize)
  };

  const getApi = async () => {
    try {
      let counting = page * pageSize + 1
      const response = await callApi("get", `admin/products?type=admin&pageNumber=${page}&pageSize=${pageSize}`)
      const modifiedData = response?.data?.data?.data?.map((data, index) => ({
        ...data,
        _id: counting + index
      }))

      setRows(modifiedData)
      setTotalRows(response?.data?.data?.count)
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getApi()
  }, [page, pageSize])
  // const handleDelete = async (id) => {
  //   try {
  //     await callApi("delete", `admin/products??type=admin&id=${id}`);
  //     setRows((prevRows) => prevRows.filter((row) => row.id !== id));
  //     setTotalRows((prevTotalRows) => prevTotalRows - 1);
  //   } catch (error) {
  //     console.error("Failed to delete product:", error);
  //   }
  // };
  const columns = [
    { field: '_id', headerName: 'S.No', width: 80 },
    {
      field: 'name',
      headerName: 'Name',
      sortable: false,

      width: 300,

      renderCell: (rowData) => {
        return (
          <div>
            {rowData?.row?.name?.length > 35 ? rowData?.row?.name.slice(0, 35) + "..." : rowData?.row?.name}
          </div>
        );
      },
    },
    {
      field: 'title',
      headerName: 'Title',
      sortable: false,

      width: 300,
      renderCell: (rowData) => {
        return (
          <div>
            {rowData?.row?.title?.length > 35 ? rowData?.row?.title.slice(0, 35) + "..." : rowData?.row?.title}
          </div>
        );
      },
    },

    {
      field: 'price',
      headerName: 'Price',
      sortable: false,

      width: 100,
      renderCell: (rowData) => {
        return (
          <div>
            {rowData?.row?.price}
          </div>
        );
      },
    },


    {
      field: 'availabeQuantity',
      headerName: 'Available Quantity',
      sortable: false,
      width: 150,

      renderCell: (rowData) => {
        return (
          <div>
            {rowData?.row?.totalQuantity ? rowData?.row?.totalQuantity : rowData?.row?.totalQuantity == 0 ? "0" : "--"} 
          </div>
        );
      },
    },

    {
      field: 'isActive',
      headerName: 'Is Active',
      sortable: false,
      width: 150,

      renderCell: (rowData) => {

        return (

          <div>

            <button style={{ marginTop: "5px", color: "#343D52", border: "1px solid white", borderRadius: '6px', background: rowData?.row?.isActive ? "#90EE90" : "#F49F9F", width: "60px", height: "40px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{rowData?.row?.isActive ? "Active" : "Inactive"}</button>
          </div>
        );
      },
    },

    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      width: 100,

      renderCell: (rowData) => {
        return (
          <div>
            <Button className="action-button" style={{ pointerEvents: "auto" }} onClick={() => handleAction(rowData?.row.id)} >{<EditIcon sx={{ color: "black" }} />}</Button>
            {/* <Button className="action-button" style={{ pointerEvents: "auto" }} onClick={() => handleDelete(rowData?.row.id)}>{<DeleteIcon sx={{ color: "black" }} />}</Button>*/}
          </div>
        );
      },
    }


  ];

  const handleRowClick = (event) => {
    setSelectedProductId(event.row.id)
    setOpen(true)

  };
  const [open, setOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);


  return (
    <>
      <div>
        <div className="m-container" style={{ margin: 'auto' }}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "25px" }}>
            <Header title="All Products" />
            <Button style={{ color: "black", border: "1px solid black", borderRadius: '4px', height: "40px" }} onClick={handleEdit} >Add Product</Button>
          </Box>
          <Box sx={{ overflow: "auto" }}>

            {
              open ?

                <ProductModal productId={selectedProductId} open={open} setOpen={setOpen} setSelectedProductId={setSelectedProductId} />
                : <></>
            }
            <DataGridTable
              handleRowClick={handleRowClick}
              height="80%"
              width="100%"
              textAlign='center'
              data={rows || []}
              columns={columns}
              pageSize={pageSize}
              rowCount={totalRows}
              onPageChange={handlePageChange}
            />
          </Box>

        </div>
      </div>
    </>
  );
};

export default AllProducts;
