import React, { useState, useEffect } from "react";
import { callApi } from "../callApi/api";
import DataGridTable from "../components/DataGrid/DataGridTable";
import { Grid, Popover } from "@mui/material";
import Box from '@mui/material/Box';
import { Header } from "../components";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from "react-router-dom";

const Orders = () => {
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const navigate = useNavigate()

  const handlePageChange = (newPage) => {
    setPage(newPage.page);
    setPageSize(newPage.pageSize)
  };
 
  const routeToStatusLogs = (id) => {
    navigate(`/ParticularStatusLogs?orderId=${id}`)
  }

  const getApi = async () => {

    try {

      const response = await callApi("get", `admin/getOrder?pageNumber=${page}&pageSize=${pageSize}`)


      const modifiedData = response?.data?.data?.rows.map((data, index) => ({
        ...data,
        _id: index + 1
      }))

      setRows(modifiedData)
      setTotalRows(response?.data?.data?.count)


    }
    catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getApi()
  }, [page, pageSize])
  const getRowHeight = (params) => {
    // Assume a default row height
    let defaultHeight = 80;
    // Check if the row has an image URL and adjust the height accordingly
    if (params.row.product && params.row.product.productDocs[0] && params.row.product.productDocs[0].url) {
      // Example: set row height to 150 if the image exists
      return 150;
    }
    return defaultHeight;
  };
 
  const HoverComponent = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    let [open, setOpen] = React.useState(Boolean)

    const { rowData } = props;

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
      setOpen(true)
    };


    const handleClose = () => {
      setAnchorEl(null);
      setOpen(false)
    };

    const id = open ? 'simple-popover' : undefined;

    return (
      <>
        <div onMouseEnter={handleClick} style={{ width:"fit-content",display: "flex", alignItems: "center", height: "100%", cursor: "pointer", pointerEvents: "auto" }}>
          <div onMouseLeave={handleClose} style={{gap: "20px", display: "flex", cursor:"pointer", pointerEvents:"auto" }}>
            <img style={{ width: "50px", height: "35px", }} src={rowData?.row?.product?.productDocs[0]?.url}></img>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              // onMouseLeave={handleClose} 
              anchorOrigin={{
                // vertical: 'left',
                // horizontal: 'right',
              }}
              slotProps={{ paper: { onMouseLeave: handleClose } }}
            >
              <img style={{ width: "300px", height: "300px", cursor:"pointer" }} src={rowData?.row?.product?.productDocs[0]?.url}></img>

            </Popover>
          </div>

        </div>
      </>
    )
  }


  const columns = [
    { field: '_id', headerName: 'S.No', width: 80 },
    {
      field: 'url',
      headerName: 'Image',
      sortable: false,
      width: 120,
      editable: false,
      renderCell: (rowData) => {
        return (
          // <div style={{display:"flex", alignItems:"center",height:"100%", cursor:"pointer", pointerEvents:"auto"}}>
          //   <img onMouseEnter={HoverComponent} style={{ width: "50px", height: "35px",  }} src={rowData?.row?.product?.productDocs[0]?.url}></img>
          // </div>
          <HoverComponent rowData={rowData} />
        );
      },
    },
    {
      field: 'price',
      headerName: 'Price',
      sortable: false,
      width: 120,
      editable: false,
      renderCell: (rowData) => {
        return (
          <div>
            {rowData?.row?.price}
          </div>
        );
      },
    },

    {
      field: 'tax',
      headerName: 'Tax',
      width: 120,
      editable: false,
      renderCell: (rowData) => {
        return (
          <div>
            {rowData?.row?.tax}
          </div>
        );
      },
    },

    {
      field: 'totalPrice',
      headerName: 'Total Price',
      width: 120,
      editable: false,
      textAline: 'center',
      renderCell: (rowData) => {
        console.log
          (rowData, "000101001111>>>>>>")
        return (
          <div>
            {rowData?.row?.totalPrice}
          </div>
        );
      },
    },

    {
      field: 'quantity',
      headerName: 'Quantity',
      sortable: false,
      textAline: 'center',
      width: 180,
      editable: false,
      renderCell: (rowData) => {
        return (
          <div>
            {rowData?.row?.quantity}
          </div>
        );
      },
    },

    {
      field: 'shippingcharge',
      headerName: 'Shipping Charge',
      sortable: false,
      textAline: 'center',
      width: 200,
      editable: false,
      renderCell: (rowData) => {
        return (
          <div>
            {rowData?.row?.shippingCharge}
          </div>
        );
      },
    },
    {
      field: 'buyer',
      headerName: 'Buyer Name',
      sortable: false,
      textAline: 'center',
      width: 200,
      editable: false,
      renderCell: (rowData) => {
        return (
          <div>
            {rowData?.row?.user?.userProfile?.name}
          </div>
        );
      },
    },
    {
      field: 'buyer mail',
      headerName: 'Buyer Mail',
      sortable: false,
      textAline: 'center',
      width: 250,
      editable: false,
      renderCell: (rowData) => {
        return (
          <div>
            {rowData?.row?.user?.email}
          </div>
        );
      },
    },

    {
      field: 'sq',
      headerName: 'View',
      sortable: false,
      textAline: 'center',
      width: 200,
      editable: false,
      renderCell: (rowData) => {
        return (
          <div>
           <VisibilityIcon onClick={() => {routeToStatusLogs(rowData?.row?.id)}} sx={{cursor:"pointer", pointerEvents:"auto"}}/>
          </div>
        );
      },
    },

  ];
  const editing = { allowDeleting: true, allowEditing: true };
  return (
    <div className="m-container" style={{ margin: 'auto' }}>

      <Header category="Page" title="Orders" />

      <Box sx={{ border: '1px solid black', overflow: "auto" }}>

        <Grid item xs={12}>
          <DataGridTable
            height="80%"
            width="100%"
            data={rows || []}
            columns={columns}
            pageSize={pageSize}
            rowCount={totalRows}
            onPageChange={handlePageChange}
            // getRowHeight={getRowHeight}
          />
        </Grid>

      </Box>

    </div>
  );
};

export default Orders;
