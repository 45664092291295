import React from "react";
import "./Styles/style.css"
import {
    Typography, TextField, FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
} from "@mui/material";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Avatar } from "@mui/material";
import dayjs from 'dayjs';
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { callApi } from "../../callApi/api";
dayjs.extend(utc);
dayjs.extend(timezone);

const EditProfile = () => {
    const [user, setUser] = React.useState({})
    const [otherInputs, setOtherInputs] = React.useState(
        {
            dob: "",
            gender: null,
            name: "",
            email: "",
            mobile: ""
        })
    const handleOtherInputs = (e) => {
        const name = e.target.name;
        const value = e.target.value
        // if (name === "name") {
        //     if (value !== "" && !/^[A-Za-z\s]+$/.test(value)) {
        //         console.log("alphabets only")

        //     }
        //     else {
        //         setOtherInputs((prevState) => ({
        //             ...prevState,
        //             [name]: value
        //         }));
        //     }
        // }
        // if (name === "mobile") {
        //     if (value !== "" && !/^[0-9]+$/.test(value)) {
        //         console.log("Numbers only")

        //     }
        //     else {
        //         setOtherInputs((prevState) => ({
        //             ...prevState,
        //             [name]: value
        //         }));
        //     }
        // }
        // if (name === "email") {
        //     if (otherInputs.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(otherInputs.email)) {
        //         console.log("Email is not valid")
        //     }
        //     else {
        //         setOtherInputs((prevState) => ({
        //             ...prevState,
        //             [name]: value
        //         }));
        //     }
        // }

        setOtherInputs((prevState) => ({
            ...prevState, [name]: value
        }))
    }
    const handleButtonChange = async () => {
        let obj = {
            adminId: "c25dd15f-a1c2-489a-8dfc-a7e267755210",
            name: otherInputs.name,
            dob: otherInputs.dob,
            gender: parseInt(otherInputs.gender)
        }
        const response = await callApi("post", "admin/adminprofile", obj)
    }

    return (
        <>
            <div style={{ padding: "20px", display: 'flex', gap: '20px', alignItems: 'center', justifyContent: 'center', }}>
                <div className="pro containers d-flex flex-column justify-content-center align-items-start rounded" style={{ width: "90%", height: '100%', boxShadow: 'rgb(113 122 131 / 11%) 0px 7px 30px 0px' }}>
                    <div className="Frame427319601">
                        <div>
                            <Avatar ></Avatar>
                            <Typography className="My-Profile" sx={{ paddingTop: '10px' }}>My Profile</Typography>
                        </div>
                        <div className="Frame427319602">
                            <button onClick={handleButtonChange} className="SmallButton" style={{ color: "black" }} >Submit</button>
                        </div>
                    </div>
                    <div className="Frame427319637">

                        <div className="Frame427319636">
                            <Typography className="NeelKapadia">{user?.firstName} {user?.lastName}</Typography>
                            <Typography className="neelkapadia123gmail">{user?.email}</Typography>
                        </div>
                    </div>
                    <div className="Frame427319585">
                        <div className="Frame427319576">
                            <div className="Frame427319574">
                                <div className="Frame427319633">
                                    <Typography className="InputLabel">Name<span style={{ color: "red" }}>*</span></Typography>
                                    <TextField className="lorem" type="text" name="name" value={otherInputs.name} onChange={handleOtherInputs}></TextField>
                                </div>
                                <div className="Frame427319633">
                                    <Typography className="InputLabel">Email<span style={{ color: "red" }}>*</span></Typography>
                                    <TextField className="lorem" type="text" name="email" value={otherInputs.email} onChange={handleOtherInputs}></TextField>
                                </div>
                            </div>
                            <div className="Frame427319574">
                                <div className="Frame427319633">
                                    <FormControl>
                                        <label style={{ color: "#000" }} htmlFor="gender" className="form-label" onChange={handleOtherInputs}>Gender<span style={{ color: "red" }}  >*</span></label>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            //   onChange={handleFileData}
                                            name="radio-buttons-group"
                                            onChange={handleOtherInputs}
                                            sx={{
                                                color: 'black',
                                                display: 'flex',
                                                flexDirection: 'row'
                                            }}
                                        >
                                            <FormControlLabel
                                                name="gender"
                                                value={0}
                                                control={
                                                    <Radio
                                                        sx={{
                                                            color: 'black',
                                                            '&.Mui-checked': { color: 'black' }
                                                        }}
                                                    />
                                                }
                                                label="Male"
                                                checked={parseInt(otherInputs.gender) === 0}
                                            />
                                            <FormControlLabel
                                                name="gender"
                                                value={1}
                                                control={
                                                    <Radio
                                                        sx={{
                                                            color: 'black',
                                                            '&.Mui-checked': { color: 'black' }
                                                        }}
                                                    />
                                                }
                                                label="Female"
                                                checked={parseInt(otherInputs.gender) === 1}
                                            />
                                            <FormControlLabel
                                                name="gender"
                                                value={2}
                                                control={
                                                    <Radio
                                                        sx={{
                                                            color: 'black',
                                                            '&.Mui-checked': { color: 'black' }
                                                        }}
                                                    />
                                                }
                                                label="Other"
                                                checked={parseInt(otherInputs.gender) === 2}
                                            />

                                        </RadioGroup>
                                    </FormControl>

                                </div>
                                {/* <div className="Frame427319633">
                                    <Typography className="InputLabel">Email<span style={{ color: "red" }}>*</span></Typography>
                                    <TextField className="lorem" type="text"></TextField>
                                </div> */}
                            </div>
                            <div className="Frame427319574">
                                <div className="Frame427319633">
                                    <Typography className="InputLabel">Mobile Number<span style={{ color: "red" }}>*</span></Typography>
                                    <TextField className="lorem" type="text" name="mobile" value={otherInputs.mobile} onChange={handleOtherInputs}></TextField>
                                </div>

                                <div className="Frame427319633">
                                    <Typography>Date of birth<span style={{ color: "red" }}>*</span></Typography>

                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                    >
                                        <DatePicker
                                            sx={{ width: "100%", border: "1px solid black", borderRadius: "12px" }}
                                            className="datepicker-event"
                                            // inputRef={dpRef}
                                            views={["year", "month", "day"]}
                                            openTo="year"
                                            value={dayjs(otherInputs.dob)}
                                            onChange={(date) => {
                                                const selectedDate = new Date(date);
                                                // const formattedDate = selectedDate.toLocaleDateString('en-US', {
                                                //     year: 'numeric',
                                                //     month: '2-digit',
                                                //     day: '2-digit',
                                                // });
                                                const formattedDate = dayjs(selectedDate).format('YYYY-MM-DD');
                                                handleOtherInputs({
                                                    target: {
                                                        name: "dob",
                                                        value: formattedDate,
                                                    },
                                                });
                                            }}
                                        />
                                    </LocalizationProvider>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EditProfile; 