import React, { useEffect, useState } from "react";
import { Stack } from "@mui/system";
import { IconButton, MenuItem, Select, Typography } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

export default function MultiLevelMenu({
  options,
  selectedCategory,
  placeholder,
  onSelectedCategory,
}) {
  const [categoryIds, setCategoryIds] = useState([null]);
  const [value, setValue] = useState("");
  const categoryKeys = options && Object.keys(options);
  const [category, setCategory] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const lastId = categoryIds[categoryIds.length - 1];
    options && setCategory(options && options[lastId]);
  }, [categoryIds, options]);

  return (
    <Select
      placeholder={placeholder}
      open={isOpen}
      value={value}
      onClick={(e) => {
        setIsOpen(!isOpen);
      }}
      MenuProps={{
        PaperProps: {
          onClick: (event) => {
            event.stopPropagation();
          },
        },
      }}
    >
      <Stack
        direction={"row"}
        component={MenuItem}
        alignItems="center"
        justifyContent="space-between"
        width={"100%"}
      >
        <IconButton
          disabled={categoryIds.length === 1}
          onClick={() => setCategoryIds((prev) => prev.slice(0, -1))}
        >
          <KeyboardArrowLeft />
        </IconButton>
        <Typography variant="body2" color={"GrayText"} pr={2}>
          {selectedCategory?.category.categoryName || ""}
        </Typography>
      </Stack>
      {category &&
        category.map((item) => (
          <Stack
            component={MenuItem}
            direction={"row"}
            justifyContent={"space-between"}
            value={item.categoryName}
            key={item.id}
            onClick={(e) => {
              if (!categoryKeys.includes(item.id)) {
                console.log(!categoryKeys.includes(item.id));
                setIsOpen(false);
              }
              e.preventDefault();
              onSelectedCategory((prevData) => ({
                ...prevData,
                category: {
                  ...prevData.category,
                  categoryName: item.name,
                  categoryId: item.id,
                },
              }));
              setValue(item.name);
              categoryKeys.includes(item.id) &&
                setCategoryIds((prev) => [...prev, item.id]);
            }}
          >
            {item.name}
            {categoryKeys.includes(item.id) ? <KeyboardArrowRight /> : ""}
          </Stack>
        ))}
    </Select>
  );
}
