import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import App from "./App";
import { ContextProvider } from "./contexts/ContextProvider";
import Login from "./loginPage";
import LoginPage from "./loginPage";
import MainPage from "./mainLogin";
ReactDOM.render(
  <React.StrictMode>
    <ContextProvider>
      <App />
      {/* <LoginPage/> */}
      {/* <MainPage/> */}
    </ContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
