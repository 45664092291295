import React,{useEffect, useState} from "react"
import DataGridTable from "./usermanagement/datagridTable";
import { Header } from "../components";
import { callApi } from "../callApi/api";
import { useLocation } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const ParticularStatusLogs = () => {
 const [rows, setRows] = useState([])
 const[page, setPage] = useState(0)
 const [pageSize, setPageSize] = useState(5)
 const [totalRows, setTotalRows] = useState(0)
 const location = useLocation()
 const queryParams = new URLSearchParams(location.search)
 const paramName = queryParams.get("orderId")

 const handlePageChange = (newPage) => {
    setPage(newPage.page);
    setPageSize(newPage.pageSize)
  };

 const getData = async () => {
    const response = await callApi("get", `admin/statusLogs?orderId=${paramName}&pageNumber=${page}&pageSize=${pageSize}`)
    const modifiedData = response?.data?.data?.rows.map((prevData, index) => ({
      ...prevData,
      _id : index + 1
    }))
    setRows(modifiedData)
    setTotalRows(response?.data?.data?.count)
 }

 useEffect(() => {
    getData()
 },[page, pageSize])

 function convertToLocalTime(isoDateString) {
    // Parse the ISO date string
    const date = new Date(isoDateString);
    
    // Create an Intl.DateTimeFormat object for IST (UTC+5:30)
    const options = {
      timeZone: 'Asia/Kolkata',
      hour12: true,
      hour: 'numeric',
      minute: 'numeric'
    };
    const formatter = new Intl.DateTimeFormat('en-US', options);
    
    // Format the date to the desired string format
    const localTimeString = formatter.format(date);
  
    return localTimeString;
  }

  function formatDate(inputDate) {
    // Array of month names
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    // Split the input date into parts
    const [year, month, day] = inputDate.split('-');

    // Convert month from "01", "02", etc. to "January", "February", etc.
    const monthName = monthNames[parseInt(month, 10) - 1];

    // Construct and return the formatted date string
    return `${parseInt(day, 10)} ${monthName} ${year}`;
}
  
 const columns = [
    {
      field: 'a',
      headerName: '',
      sortable: false,
      width: 100,
      editable: false,
      renderCell: (rowData) => {
        return (
            <div>
           {rowData?.row?._id}
           </div>
        );
      },
    },
    {
      field: 'kk',
      headerName: 'Status',
      sortable: false,
      width: 350,
      editable: false,
      renderCell: (rowData) => {
        const status = rowData?.row?.status
        return (
          <div>
            <p
             style = {{
                color : status == 0 ? "#EAD41B" : status == 1 ? "#FF7F50" : status == 2 ? "#DE502A" : 
                status == 3 ? "#F3B45F" : status == 4 ? "#9FE2BF" : status == 5 ? "#2ABDDE" :
                status == 6 ? "#17A2B8" : status == 7 ? "#27AE60" : status == 8 ? "#FFA500" : 
                status == 9 ? "#F49F9F" : status == 10 ? "#BDC3C7" : "",
                 fontSize:"20px",border:"1px solid white" , borderRadius:"6px",marginTop: "5px", width:"fit-content", height: "40px", display: 'flex', alignItems: 'center', justifyContent: 'center',
                
             }}
            >
            {
             status == 0 ? "Pending" : status == 1 ? "Confirmed" : status == 2 ? "Cancel" : 
             status == 3 ? "Processed" : status == 4 ? "Dispatched" : status == 5 ? "In Transit" :
             status == 6 ? "Out for Delivery" : status == 7 ? "Delivered" : status == 8 ? "Attempted Delivery" : 
             status == 9 ? "Delivery Rescheduled" : status == 10 ? "Returned" : "" 
            }
            </p>
          </div>
        );
      },
    },
    {
      field: 'op',
      headerName: 'Created At',
      sortable: false,
      width: 200,
      editable: false,
      renderCell: (rowData) => {
        return (
         
          <div>
            {formatDate(rowData?.row?.createdAt.split("T")[0]) + " at " +  convertToLocalTime(rowData?.row?.createdAt)}
          </div>
        );
      },
    },
  ];

 return (
    <>
     <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
        <div style={{display:"flex", gap:"30px"}}>
         <ArrowBackIcon onClick={() => window.history.back()} sx={{cursor:"pointer"}}/>
        <Header title="Status Logs" />
        </div>
        
        <DataGridTable
          height="100%"
          width="100%"
          textAlign='center'
          data={rows || []}
          columns={columns}
          pageSize={pageSize}
          rowCount={totalRows}
          onPageChange={handlePageChange}
        />
      </div>
    </>
 )
}

export default ParticularStatusLogs;