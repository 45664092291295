import React from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { Button } from '..';
import { useStateContext } from '../../contexts/ContextProvider';
import avatar from "../../data/avatar.jpg";
import SettingsIcon from '@mui/icons-material/Settings';
import EmailIcon from '@mui/icons-material/Email';
import InfoIcon from '@mui/icons-material/Info';
const UserProfile = () => {
  const { currentColor } = useStateContext();

  return (
    <>
      <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96">
        <div className="flex justify-between items-center">
          <p className="font-semibold text-lg dark:text-gray-200">User Profile</p>
          <Button
            icon={<MdOutlineCancel />}
            color="rgb(153, 171, 180)"
            bgHoverColor="light-gray"
            size="2xl"
            borderRadius="50%"
          />
        </div>
        <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
          <img
            className="rounded-full h-24 w-24"
            src={avatar}
            alt="user-profile"
          />
          <div>
            <p className="font-semibold text-xl dark:text-gray-200"> Rohit Kumar Suman </p>
            <p className="text-gray-500 text-sm dark:text-gray-400">  Administrator   </p>
            <p className="text-gray-500 text-sm font-semibold dark:text-gray-400"> info@shop.com </p>
          </div>
        </div>
        <div>
          <div onClick={() => window.location.href = "/profile"} className="flex gap-5 border-b-1 border-color p-4 hover:bg-light-gray cursor-pointer  dark:hover:bg-[#42464D]" style={{ alignItems: 'center', justifyItems: 'center' }}>
            <button
              type="button"
            >
              <SettingsIcon sx={{ color: "black", fontSize: "30px" }} />

            </button>
            <p className="font-semibold dark:text-gray-200 " >Account</p>

          </div>
          <div className="flex gap-5 border-b-1 border-color p-4 hover:bg-light-gray cursor-pointer  dark:hover:bg-[#42464D]" style={{ alignItems: 'center', justifyItems: 'center' }}>
            <button
              type="button"
            >
              <EmailIcon sx={{ color: "black", fontSize: "30px" }} />

            </button>
            <p className="font-semibold dark:text-gray-200 "> Mail & messages</p>
          </div>
          <div className="flex gap-5 border-b-1 border-color p-4 hover:bg-light-gray cursor-pointer  dark:hover:bg-[#42464D]" style={{ alignItems: 'center', justifyItems: 'center' }}>
            <button
              type="button"
            >
              <InfoIcon sx={{ color: "black", fontSize: "30px" }} />

            </button>
            <p className="font-semibold dark:text-gray-200 ">About</p>
          </div>
        </div>

        <div className="mt-5">
          <Button
            color="white"
            bgColor={currentColor}
            text="Logout"
            borderRadius="10px"
            width="full"
            num={1}        
          />
        </div>

      </div>

    </>

  );
};

export default UserProfile;