import { Box } from "@mui/system";
import * as React from "react";
import CustomisedEditor from "../../ImageEditor";

export default function ProductForm(props) {

  const { inputData, setInputData } = props;

  const editorChange = (content) => {
    setInputData((prevInputData) => ({
      ...prevInputData,
      description: content,
    }));
  };

  return (
    <Box sx={{ display: "flex", gap: "24px" }}>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ display: "flex", gap: "20px", width: "100%" }}>
          <CustomisedEditor
            content={inputData.description}
            handleChange={editorChange}
          />
        </Box>
      </Box>
    </Box>
  );
}
