import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// import { TextField } from "@mui/material";
import { Button, Grid } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import { width } from "@mui/system";
import DescriptionIcon from "@mui/icons-material/Description";
// import "../../../src/components/styles/Download.css";
// import "./Styles/style.css";
const UploadCompFollowUp = (props) => {
  const {
    file,
    handleImageChange,
    name,
    sx,
    label,
    buttonText,
    textSize,
    xs,
    handleDelete,
    index,
    docSubmit,
    handleClick,
  } = props;

  const [lastSegment, setLastSegment] = useState(null);

  const [filePreview, setFilePreview] = useState(null);
  const fileInputRef = useRef(null);
  useEffect(() => {
    var flag = false;
    if (file && !flag) {
      if (typeof file === "string") {
        setFilePreview(file);
        const segments = typeof file === "string" && file?.split(".");
        const extension = segments[segments.length - 1];
        setLastSegment(extension); // Set lastSegment state
      } else if (file.name) {
        const segments = file.name.split(".");
        const extension = segments[segments.length - 1];
        setLastSegment(extension); // Set lastSegment state

        const reader = new FileReader();
        reader.onloadend = () => {
          setFilePreview(reader.result);
        };
        // Pass the file itself to readAsDataURL
        reader.readAsDataURL(file);
        flag = true;
      }
    } else {
      setFilePreview(null);
      setLastSegment(null);
    }
  }, [file]);

  const allowedExtensions = ["png", "jpeg", "jpg", "svg"];

  const text = buttonText ? buttonText : "Upload";
  const buttonSx = sx
    ? sx
    : {
        // backgroundColor: "black",
        width: "12rem",
        // color: "black",
        border: "1px dashed grey",
        height: "2.5rem",
        textTransform: "none",
        "&:hover": {
          backgroundColor: `black`,
        },
      };

  return (
    <Grid
      sx={{
        minHeight: "100px",
        maxHeight: "200px",
        display: "flex",
        flexDirection: "column",
        // border: "1px solid red",
        width: "100%",
        minWidth: "280px",
      }}
      item
      xs={xs ? xs : 4}
    >
      <Typography
        sx={{
          // color: "rgba(255, 255, 255, .5)",
          color: "grey",
          fontSize: "11px",
          height: "10px",
          marginBottom: "10px",
        }}
        variant="subtitle2"
      >
        {label}
        {/* <Box
          sx={{
            color: "red",
            marginRight: "20px",
            border: "1px solid red"

          }}
          component="span"
        >
          *
        </Box> */}
      </Typography>
      <input
        type="file"
        ref={fileInputRef}
        id={name}
        accept=".pdf, .mp4, .avi, .mov, video/*, image/*"
        // multiple
        style={{ display: "none" }}
        name={name}
        onChange={(e) => handleImageChange(e, index)}
      />
      <label htmlFor={name}>
        <Button className="" sx={buttonSx} variant="contained" component="span">
          <UploadIcon style={{ fontSize: "17px", color: "black" }} />
          &nbsp;
          <span
            style={{ fontSize: textSize ? textSize : "10px", color: "black" }}
          >{`${text}`}</span>
        </Button>
      </label>

      {filePreview && (
        <div className="d-flex justify-content-between align-items-center relative">
          {allowedExtensions.includes(lastSegment) ? (
            <img
              src={filePreview}
              alt="File Preview"
              style={{ width: "100px", height: "100px" }}
              className="mt-4"
            />
          ) : (
            <DescriptionIcon
              style={{ marginTop: "10px", height: "40px", width: "40px" }}
            />
          )}

          <DeleteIcon
            sx={{ cursor: "pointer" }}
            onClick={() =>
              docSubmit
                ? handleDelete(fileInputRef, index)
                : handleDelete(name, fileInputRef)
            }
            className="text-danger"
          />
        </div>
      )}
      {file && (
        <div className="d-flex justify-content-between align-items-center">
          {/* <Typography>{file.name}</Typography> */}
          {/* <DeleteIcon sx={{ cursor: "pointer" }} onClick={() => handleClick(name)} /> */}
        </div>
      )}
    </Grid>
  );
};

export default UploadCompFollowUp;
