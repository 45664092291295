
import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { callApi } from '../../callApi/api';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';
import { display } from '@mui/system';
const ProductModal = ({ productId, open, setOpen, setSelectedProductId }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await callApi("get", `admin/products?type=admin&id=${productId}`);
        setSelectedProduct(response?.data?.data?.data[0])
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);


  const ProductItem = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    textAlign: 'center',
    border: '1px solid #ccc',
    padding: '20px',
    borderRadius: '5px',
    color: theme.palette.text.secondary,
    '& img': {
      width: '40%',
      height: '0%',
      marginBottom: '8px',
    },
    '& div': {
      marginBottom: '8px',
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: '15px',
      transition: 'all 0.3s ease',
    },
    '&:hover div': {
      textOverflow: 'clip',
      whiteSpace: 'normal',
    }
  }));

  function FormRow({ product }) {
    return (
      <React.Fragment >

        <Grid sx={{ marginLeft: '10%', marginTop: '5%' }} item xs={12} sm={6} md={4}>

          <ProductItem>
            <button style={{ pointerEvents: "auto", marginLeft: '80%' }} onClick={handleClose} >{<DisabledByDefaultOutlinedIcon />}</button>
            <img src={product?.productDocs[0]?.url} />
            <div><b>Name:</b> {product?.name}</div>
            <div><b>Title:</b> {product?.title}</div>
            <div><b>Total Quantity:</b> {product?.inventory?.totalQuantity}</div>
            <div><b>Available Quantity:</b> {product?.inventory?.availabeQuantity}</div>
            <div><b>Ordered Quantity:</b> {product?.inventory?.orderedQuantity}</div>
            <div><b>Price:</b> {product?.price}</div>
            <div><b>Discount Price:</b> {product?.discountPrice}</div>
            <div><b>Description:</b> {product?.description.replace(/<\/?[^>]+(>|$)/g, "")}</div>
            <div><b>Highlights:</b> {product?.highlights}</div>

          </ProductItem>
        </Grid>

      </React.Fragment>
    );
  }

  const handleClose = () => {
    setOpen(false);
    setSelectedProductId(null);
    setSelectedProduct(null);
  };

  return (
    <div>

      <Modal

        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          {selectedProduct && (

            <Grid container spacing={3} sx={{ marginLeft: '30%' }}>

              <FormRow product={selectedProduct} />

            </Grid>

          )}
        </div>

      </Modal>
    </div>
  );
}
export default ProductModal;






