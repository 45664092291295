import React, { useState } from 'react';
import '../src/loginPage.css';
export default function MainPage() {
    
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleLogin = (event) => {
    event.preventDefault(); 
    

    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;

    // Validation
     if (!passwordPattern.test(password)) {
        setErrorMessage('Password must be at least 6 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character.');
      } else if (password !== confirmPassword) {
        setErrorMessage('Passwords do not match.');
      } else {
        
        
            window.location.href = '/login'; 
        
      }
  };
  return (
    <>
     
      <form>

        <div class="container"> 
            <h1>Admin Login</h1>
            <h2>Password </h2>   
            <input
            type="pswd"
            placeholder="Enter Password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <h2> Conform Password </h2>
          <input   
            type="cnfpswd"
            placeholder="Confirm Password"
            name="confirmPassword"
            value={confirmPassword} 
            onChange={(e) => setConfirmPassword(e.target.value)}/>
        

            
            <button  className='send'type="submit" onClick={handleLogin}>Proceed</button> 
            {errorMessage && <div className="error">{errorMessage}</div>}
            <div className='checkbox1'>
                <div className='remember'><input type="checkbox" checked="checked"/> Remember me <br /> <br />
                </div>
            
         
                </div> 
            
        </div>   
    </form>     


      
    </>
  );
}

