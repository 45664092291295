import {
  TextField,
  Typography,
  Box,
  Button,
  Select,
  MenuItem,
  Grid,
  FormControl,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Styles/style.css";
import UploadComp from "../../UploadComp";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { callApi } from "../../callApi/api";
import ProductForm from "./ProductForm";
import MultiLevelDropdown from "./MultiLevelDropdown";
import { useLocation } from "react-router-dom";
import CustomizedSnackbars from "../Snackbar";

const EditAllProducts = () => {
  const [categories, setCategory] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [product, setProduct] = useState({
    category: { categoryName: "", categoryId: "" },
    productName: "",
    specification: "",
    discountPrice: "",
    price: "",
    highlights: "",
    vendor: "",
    tag: "",
    description: "",
    productTitle: "",
    variant: "",
    // variant: [{ variantTitle: "", variant: "" }],
    isActive: true,
    variantId: "",
    quantity: "",
  });
  const [editIndex, setEditIndex] = useState(true);
  const [storedArray, setStoredArray] = useState([]);
  const [snackBarMsg, setSnackBarMsg] = React.useState({
    message: "",
    severity: "success",
    open: false,
  });
  const [categoriess, setCategories] = useState([]);
  let [variantFlag, setVariantFlag] = useState(false);

  const handleCategories = async () => {
    try {
      const response = await callApi("get", "admin/getCategories");
      const data = response?.data?.data;
      setCategories(data);
    } catch (err) {
      console.log(err);
    }
  };

  const [hook, setHook] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const productId = searchParams.get("productId");
  const [inputData, setInputData] = React.useState({
    description: "",
  });

  const [imageIndex, setImageIndex] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("");

  const handleSelectCategory = (categoryId) => {
    setSelectedCategory(categoryId);
  };
  const [imageFiles, setImageFiles] = useState([{ files: null }]);
  const [pitch, setPitch] = useState("");

  const handleImageChange = (event, index) => {
    const newFilesData = [...imageFiles];
    newFilesData[index].files = event.target.files[0];
    setImageFiles(newFilesData);
    setImageIndex(index);
  };

  React.useEffect(() => {
    if (imageFiles.length < storedArray.length) {
      setHook(true);
    }
  }, [imageFiles]);

  const handleCategory = async () => {
    try {
      let categoryNames;

      const response = await callApi(
        "get",
        `admin/products?type=admin&id=${productId}`
      );
      const data = response?.data?.data?.data[0];
      for (let key of categoriess) {
        if (key.id === product.category.categoryId) {
          categoryNames = key.name;
        }
      }
      let variantJsonData;
      let key;

      if (Array.isArray(JSON.parse(data?.variantJson)))
        variantJsonData =
          data?.variantJson != null && JSON.parse(data?.variantJson)[0];
      else
        variantJsonData =
          data?.variantJson != null && JSON.parse(data?.variantJson);

      if (
        (data?.variantJson != null &&
          !Array.isArray(JSON.parse(data?.variantJson))) ||
        Object.keys(variantJsonData).length == 1
      ) {
        key = variantJsonData != null && Object.keys(variantJsonData)[0];
        setVariantFlag(true);
      }

      setCategory(data);
      setProduct({
        ...product,
        category: {
          categoryName: categoryNames,
          categoryId: data?.categoryId,
        },
        productName: data?.name,
        specification: data?.specification,
        discountPrice: data?.discountPrice,
        price: data?.price,
        highlights: data?.highlights,
        vendor: data?.vendor,
        quantity: data?.totalQuantity,
        tag: data?.metaTags,
        // productTitle: Array.isArray(JSON.parse(data?.variantJson)) ? data?.title : data?.variantJson != null ? Object.keys(variantJsonData)[0] : data?.title,
        productTitle: data?.title,
        isActive: data?.isActive,
        variant: data.variantJson != null && JSON.parse(data?.variantJson)[key],
        // variant: JSON.parse(data?.variantJson),
        variantId: data?.variantId,
      });
      setInputData({
        description: data?.description,
      });

      let array =
        data?.productDocs.length < 1
          ? [{ files: null, boolean: true, flag: 1 }]
          : [];
      let newArray = [];
      for (let datas of data?.productDocs) {
        array.push({ files: datas.url, boolean: false, flag: 2, id: datas.id });
        newArray.push({ files: datas.url });
      }

      setImageFiles(array);
      setStoredArray(newArray);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (productId) {
      handleCategory();
    }
  }, [categoriess]);

  const uploadProductApi = async () => {
    try {
      let isValid = false;
      for (let key of imageFiles) {
        if (key.files == null) {
          isValid = true;
          break;
        }
      }

      // if(!product.productName || !product.productTitle || !product.category.categoryName || !product.specification
      //     || !product.price || !product.discountPrice || !product.highlights || !product.quantity
      //      || !product.vendor || !product.tag || !inputData.description || isValid
      // ){
      //   setSnackBarMsg({
      //      message: "Please fill all required fields",
      //      severity:"error",
      //      open: true
      //   })
      //   return
      // }

      const formData = new FormData();
      const updatedImageFiles = Object.keys(imageFiles).map((key) => {
        const file = imageFiles[key];
        if (typeof file.files === "string") {
          return {
            ...file,
            status: file.boolean ? "Deleted" : "Not Edited",
          };
        } else if (typeof file.files === "object") {
          return {
            ...file,
            status: "Created",
            id: null,
          };
        }
      });
      setImageFiles(updatedImageFiles);
      formData.append("categoryId", product?.category?.categoryId);
      formData.append("title", product?.productTitle);
      formData.append("name", product?.productName);
      formData.append("price", product?.price);
      formData.append("discountPrice", product?.discountPrice);
      formData.append("vendorId", product?.vendor);
      formData.append("description", inputData.description);
      formData.append("highlights", product?.highlights);
      formData.append("totalQuantity", product?.quantity);
      formData.append("specification", product?.specification);
      formData.append("isActive", product?.isActive);
      formData.append("variantId", product?.variantId);
      formData.append("metaTags", product?.tag);
      for (let key of imageFiles) {
        if (typeof key.files === "string" && key.boolean) {
          formData.append(
            "files",
            JSON.stringify({ files: key.files, id: key.id })
          );
        } else if (typeof key.files === "object") {
          formData.append("files", key.files);
        }
      }
      const response = await callApi(
        "post",
        `admin/productUpload?productId=${productId}`,
        formData,
        { "content-type": "multipart/form-data" },
        true,
        true
      );
      if (response?.data?.success == true) {
        window.location.href = "AllProducts";
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSeller = async () => {
    try {
      const response = await callApi("get", "seller/getAllSeller");
      if (response.data.success) {
        const data = response.data.data;
        setSellers(data);
      } else {
        setSellers([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleCategories();
    handleSeller();
  }, []);

  const handleProduct = (event, index) => {
    const { name, value } = event.target;
    setProduct((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCallModel = (fieldType) => {
    if (fieldType === "imageFiles") {
      setImageFiles((prevImageFiles) => [
        ...prevImageFiles,
        { files: null, boolean: true, flag: 1 },
      ]);
    } else if (fieldType === "inputData") {
      setInputData(...inputData, {
        description: "",
      });
    }
  };
  const handleDeleteModel = (index) => {
    const newInputData = [...imageFiles];
    newInputData.splice(index, 1);
    setImageFiles(newInputData);
    setInputData(newInputData);
  };

  const handleDelete = (index, fileInputRef) => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setImageFiles((prevData) => {
      const newArray = [...prevData];
      let obj = newArray[index];
      obj.files = null;
      return newArray;
    });
  };

  const handleVariantId = (variantId) => {
    window.location.href = `products?variantId=${variantId}`;
  };

  return (
    <>
      <div
        style={{
          padding: "20px",
          display: "flex",
          gap: "20px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className="pro containers d-flex flex-column justify-content-center align-items-start rounded"
          style={{
            width: "90%",
            height: "100%",
            boxShadow: "rgb(113 122 131 / 11%) 0px 7px 30px 0px",
          }}
        >
          <Box
            sx={{
              marginBottom: "20px",
              display: "flex",
              justifyContent: "space-between",
              padding: "20px",
            }}
          >
            <Typography sx={{ fontSize: "20px" }}>Product Details</Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "20px",
              }}
            >
              <Button
                sx={{
                  background: "white",
                  color: "black",
                  border: "1px solid black",
                }}
                onClick={() => window.history.back()}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  "&:hover": { color: "black" },
                  background: "black",
                  color: "white",
                  "&:hover": { backgroundColor: "black" },
                }}
                onClick={uploadProductApi}
              >
                Upload Product
              </Button>
            </div>
          </Box>

          <Grid container columnSpacing={7} px={5} rowSpacing={3}>
            {/* <Grid item xs={12} sm={6}>
                            <Typography className="My-Profile" sx={{ marginTop: "20px" }}>Product Details</Typography>
                            {/* <NavBar isActive={categories?.isActive} handleChange={handleIsActiveChange} /> 

                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: "20px" }}>
                            <Button sx={{ "&:hover": { color: "black" }, background: "black", color: "white", "&:hover": { backgroundColor: "black" } }} onClick={uploadProductApi}>Upload Product</Button>
                        </Grid> */}
            <Grid item xs={12} sm={6}>
              <Typography className="InputLabel">
                Product Name<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                className="lorem"
                type="text"
                value={product?.productName}
                name="productName"
                onChange={handleProduct}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="InputLabel">
                Product Title<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                className="lorem"
                type="text"
                value={product?.productTitle}
                name="productTitle"
                onChange={handleProduct}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="InputLabel">
                Category<span style={{ color: "red" }}>*</span>
              </Typography>
              <Select
                className="form-control state-event"
                style={{
                  color: "black",
                  width: "100%",
                  fontSize: "14px",
                }}
                id="category"
                name="category"
                value={product?.category?.categoryName}
                placeholder="Select state"
                IconComponent={KeyboardArrowDownIcon}
                onChange={handleProduct}
              >
                <MultiLevelDropdown
                  onSelectedCategory={setProduct}
                  products={product}
                />
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="InputLabel">
                Specification<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                className="lorem"
                type="text"
                name="specification"
                value={product?.specification}
                onChange={handleProduct}
              ></TextField>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography className="InputLabel">
                Price<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                className="lorem"
                type="text"
                name="price"
                value={product?.price}
                onChange={handleProduct}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="InputLabel">
                Discount Price<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                className="lorem"
                type="text"
                name="discountPrice"
                value={product?.discountPrice}
                onChange={handleProduct}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="InputLabel">
              Composition & Care<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                className="lorem"
                type="text"
                name="highlights"
                value={product?.highlights}
                onChange={handleProduct}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="InputLabel">
                Total Stock<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                className="lorem"
                type="text"
                name="quantity"
                value={product?.quantity}
                onChange={handleProduct}
              ></TextField>
            </Grid>
            <Grid component={FormControl} item xs={12} sm={6}>
              <Typography>
                Seller<span style={{ color: "red" }}>*</span>
              </Typography>
              <Select
                // className="form-control state-event"
                style={{
                  color: "black",
                  width: "100%",
                  fontSize: "14px",
                }}
                id="seller"
                name="vendor"
                value={product.vendor || ""}
                onChange={handleProduct}
                displayEmpty
                IconComponent={KeyboardArrowDownIcon}
              >
                {sellers.map((seller) => (
                  <MenuItem key={seller.id} value={seller.id}>
                    {seller.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="InputLabel">
                Tags<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                className="lorem"
                type="text"
                name="tag"
                value={product?.tag}
                onChange={handleProduct}
              ></TextField>
            </Grid>
          </Grid>
          <Grid container columnSpacing={7} px={5} rowSpacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "15px",
                  marginTop: "30px",
                }}
                variant="subtitle2"
              >
                Description<span style={{ color: "red" }}>*</span>
              </Typography>
              <>
                <React.Fragment>
                  <ProductForm
                    inputData={inputData}
                    setInputData={setInputData}
                  />
                </React.Fragment>
              </>
            </Grid>

            {/* <Grid item xs={12} sm={12}>
                            <Box sx={{ width: "200px", height: "150px", border:"1px solid red" }}>
                                <img src={categories?.productDocs?.length > 0 && categories?.productDocs[0]?.url}></img>
                            </Box>
                        </Grid> */}

            <Grid item xs={12} sm={12}>
              {imageFiles && imageFiles ? (
                <>
                  <Typography className="InputLabel">
                    Upload Images & Videos
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  {imageFiles?.map((file, index) => (
                    <>
                      {file.files != null && file.flag != 1 ? (
                        <Box
                          sx={{
                            marginTop: imageFiles.length > 1 ? "40px" : "10px",
                            width: "200px",
                            height: "150px",
                            display: "flex",
                            gap: "20px",
                          }}
                        >
                          <div style={{ opacity: file.boolean && 0.5 }}>
                            <img
                              style={{ color: "red" }}
                              src={file.files}
                            ></img>
                          </div>

                          <Box>
                            <Button
                              onClick={() => {
                                if (file.boolean) {
                                  let array = [...imageFiles];
                                  array[index].boolean = false;
                                  setImageFiles(array);
                                } else {
                                  let newArray = [...imageFiles];
                                  newArray[index].boolean = true;
                                  setImageFiles(newArray);
                                }
                              }}
                              sx={{
                                color: "white",
                                backgroundColor: "black",
                                "&:hover": { backgroundColor: "black" },
                              }}
                            >
                              {file.boolean ? "Restore" : "Delete"}
                            </Button>
                          </Box>
                        </Box>
                      ) : (
                        <></>
                      )}

                      {file.files === null || file.flag === 1 ? (
                        <UploadComp
                          bro={1}
                          index={index}
                          file={imageFiles[index].files}
                          name={`upload${index}`}
                          handleImageChange={handleImageChange}
                          handleDelete={handleDelete}
                          buttonText={`Upload Image ${index + 1}`}
                          sx={{
                            backgroundColor: "white",
                            borderRadius: "0.5rem",
                            width: "100%",
                            color: "black",
                            border: "1px dashed grey",
                            height: "3rem",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: `white`,
                            },
                          }}
                          textSize="16px"
                          handleClick={() => handleDeleteModel(index)}
                          variant={"contained"}
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  ))}
                  <Box sx={{ paddingTop: "40px", width: "100%" }}>
                    <Button
                      onClick={() => handleCallModel("imageFiles")}
                      variant="contained"
                      sx={{
                        border: "1px solid black",
                        color: "black",
                        borderRadius: "8px",
                        backgroundColor: "white",
                      }}
                    >
                      Add More Images
                    </Button>
                  </Box>
                </>
              ) : (
                <></>
              )}
            </Grid>

            {variantFlag && (
              <Grid item xs={12} sm={6}>
                <Typography>Variant</Typography>
                <TextField
                  sx={{ marginBottom: "30px", width: "100%" }}
                  name="variant"
                  value={product.variant}
                  // onChange={handleProduct}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            )}

            {!variantFlag && (
              <Grid item xs={12} sm={6}>
                <Button
                  sx={{
                    "&:hover": { color: "black" },
                    background: "black",
                    color: "white",
                    marginBottom: "30px",
                  }}
                  onClick={() => handleVariantId(product?.variantId)}
                >
                  Add Variants
                </Button>
              </Grid>
            )}
          </Grid>
        </div>
      </div>
      <CustomizedSnackbars msgData={snackBarMsg} setMsgData={setSnackBarMsg} />
    </>
  );
};
export default EditAllProducts;
