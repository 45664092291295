import React from 'react'
import Image from './pages/images/Screenshot 2024-04-05 at 11.26.59 AM.png'
import '../src/blank.css'
export default function Blank() {
  return (
    <>
        <div className='blank'>
      <div className='image1'><img src={Image}/></div>
       <br />
       <div className='result'><h1 >Sorry, no results found!</h1></div>
        <div className='part'><h2 >Please check the spelling or try searching for something else</h2></div>
 
         
       
 
        
         
    
</div>

</>
  )
}
