import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { Button, Typography, TextField } from '@mui/material';
import { useState } from 'react';

const useStyles = makeStyles({
    customRowStyling: {
       
    },
  });
export default function DataGridTable(props) {
    const [searchValue, setSearchValue] = useState('');
    const classes = useStyles()
    
    const routeToCompanyData = ()=>{
        window.location.href='/companyWall'
    }

    const AddNewAssign = () => {
        window.location.href= `/${props.route}`
      }

    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
      };
    
    const getRowId = (row) => {
      return row._id; // Assuming 'id' is a unique identifier for each row
    };
    return (
        <Box className={classes.customRowStyling} sx={{ width:`${props.width}`, borderRadius: '10px',  }}>
            <Box style={{display:'flex', justifyContent:'space-between' }}>
                <Typography variant='h4' sx={{color:'black'}}>{props.heading}</Typography>
            {props.button ? (
                 <Button sx={{
                    width: "fit-content",
                    height: "fit-content",
                    color: "white",
                    borderColor: "white",
                    backgroundColor: "black",
                    borderRadius: "0.5rem",
                    "&:hover": {
                      borderColor: "white",
                      backgroundColor:"black"
                    },
                  }} variant='contained' onClick={AddNewAssign}>{props.button}</Button>
            ): (
              <></>
                
          //       <TextField
          //   id="search"
          //   label="Search"
          //   variant="outlined"
          //   size="small"
          //   value={searchValue}
          //   onChange={handleSearchChange}
          //   sx={{ width: '200px' }} // Add custom styles for the search bar
          // />
            )}
       
      </Box>
            <DataGrid
            localeText={{ noRowsLabel: 'No Records Found' }}
            getRowId={getRowId}
            onRowClick={props.handleRowClick}
             className={classes.customRowStyling}
             pageSizeOptions={[5,10]}
                sx={{
                    '& .MuiDataGrid-colCell': {
                        borderTop: '1px solid #ccc', // Add a top border to header cells
                      },
                      '& .MuiDataGrid-row': {
                        pointerEvents: 'auto', // Disable pointer events for rows
                      },
                      '& .MuiTablePagination-toolbar': {
                        alignItems:"baseline"
                      },
                    fontSize: '14px',
                    color:'black',
                    border:'none',
                    scrollbarWidth:"thin !important"
                }}
                
                rows={props.data}
                columns={props.columns}
                checkboxSelection={props?.checkBoxEnabled ? true: false}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: props.pageSize,
                    },
                  },
                }}
                autoHeight={true}
                rowCount={props.rowCount}
                paginationMode="server"
                onCellEditStop={props.onCellEditStop}
                onPaginationModelChange={props?.onPageChange}
                hideFooterSelectedRowCount
            />
        </Box>
    )
}