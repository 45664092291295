import { useEffect, useLayoutEffect, useState } from "react";
import {
  TextField,
  Typography,
  Box,
  Button,
  Select,
  Grid,
  Modal,
  MenuItem,
  Stack,
  FormControl,
} from "@mui/material";
import "./Styles/style.css";
import UploadCompFollowUp from "../../UploadComp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { callApi } from "../../callApi/api";
import ProductForm from "./ProductForm";
import NavBar from "../../ToggleButton";
import MultiLevelDropdown from "./MultiLevelDropdown";
import AddCategory from "../menu/mainMenu";
import { useLocation } from "react-router-dom";
import CustomizedSnackbars from "../Snackbar";

const AllProducts = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const variantId = searchParams.get("variantId");
  const [product, setProduct] = useState({
    category: { categoryName: "", categoryId: "" },
    productName: "",
    specification: "",
    uploadImage: "",
    discountPrice: "",
    price: "",
    quantity: "",
    highlights: "",
    vendor: "",
    tag: "",
    description: "",
    productTitle: "",
    variant: "",
    isActive: true,
  });
  const [snackBarMsg, setSnackBarMsg] = useState({
    message: "",
    severity: "success",
    open: false,
  });
  const [category, setCategory] = useState([]);
  const [sellers, setSellers] = useState([]);

  const [inputData, setInputData] = useState({
    description: "",
  });
  const [open, setOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [imageFiles, setImageFiles] = useState([{ files: null }]);

  const handleImageChange = (event, index) => {
    const newFilesData = [...imageFiles];
    newFilesData[index].files = event.target.files[0];
    setImageFiles(newFilesData);
    setImageIndex(index);
  };

  const handleCategory = async () => {
    try {
      const response = await callApi("get", "admin/getCategories");
      const data = response.data.data;
      console.log(data);
      data.map((item) =>
        setCategory((prev) => ({
          ...prev,
          [item.parentId]: [...(prev[item.parentId]||[]), item],
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleSeller = async () => {
    try {
      const response = await callApi("get", "seller/getAllSeller");
      if (response.data.success) {
        const data = response.data.data;
        setSellers(data);
      } else {
        setSellers([]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const uploadProductApi = async () => {
    setLoading(true);
    try {
      let variantValid = false;
      for (let key of product.variant) {
        if (key.variant === "" || key.variantTitle === "") {
          variantValid = true;
          break;
        }
      }

      let isValid = false;
      for (let key of imageFiles) {
        if (key.files == null) {
          isValid = true;
          break;
        }
      }

      if (
        !product.productName ||
        !product.productTitle ||
        !product.category.categoryName ||
        !product.specification ||
        !product.price ||
        !product.highlights ||
        !product.quantity ||
        !product.vendor ||
        !product.tag ||
        !inputData.description ||
        isValid ||
        variantValid
      ) {
        setSnackBarMsg({
          message: "Please fill all required fields",
          severity: "error",
          open: true,
        });
        setLoading(false);

        return;
      }

      const formData = new FormData();
      formData.append("categoryId", product?.category?.categoryId);
      if (variantId) {
        let variantJson = {
          [product.productTitle]: product.variant,
        };
        formData.append("variantJson", JSON.stringify(variantJson));
      } else {
        formData.append("title", product?.productTitle);
      }
      formData.append("name", product?.productName);
      formData.append("price", product?.price);
      formData.append("discountPrice", product?.discountPrice);
      formData.append("description", inputData.description);
      formData.append("highlights", product?.highlights);
      formData.append("totalQuantity", product?.quantity);
      formData.append("specification", product?.specification);
      formData.append("isActive", product?.isActive);
      formData.append("vendorId", product?.vendor);

      // formData.append('variantJson', JSON.stringify(variantJson));
      // for(let key of product.variant){
      //     formData.append("variantImages", key.variantImages)
      // }
      formData.append("metaTags", product?.tag);
      for (const ImageData of imageFiles) {
        formData.append("files", ImageData.files);
      }
      if (variantId) {
        formData.append("variantId", variantId);
      }
      const response = await callApi(
        "post",
        "admin/productUpload",
        formData,

        { "content-type": "multipart/form-data" },
        true,
        true
      );
      if (response?.data?.success == true) {
        window.location.href = "AllProducts";
      } else {
        console.error(Error);
      }
      setLoading(true);
    } catch (error) {
      console.log(error);
      setLoading(true);
    }
  };

  const handleProduct = (event, index) => {
    const { name, value, files } = event.target;

    if (files != null) {
      const newVariantImages = { ...product };
      newVariantImages.variant[index].variantImages = event.target.files[0];
      setProduct(newVariantImages);
    } else {
      setProduct((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  useLayoutEffect(() => {
    handleCategory();
    handleSeller();
  }, []);
  

  const handleIsActiveChange = () => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      isActive: !prevProduct?.isActive,
    }));
  };

  const handleTopDeals = () => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      TopDeals: !prevProduct?.TopDeals,
    }));
  };

  const handleCallModel = (fieldType) => {
    if (fieldType === "imageFiles") {
      setImageFiles((prevImageFiles) => [...prevImageFiles, { files: null }]);
    } else if (fieldType === "inputData") {
      setInputData(...inputData, {
        description: "",
      });
    }
  };

  const handleDeleteModel = (index) => {
    const newInputData = [...imageFiles];
    newInputData.splice(index, 1);
    setImageFiles(newInputData);
    setInputData(newInputData);
  };
  const handleAddVariant = () => {
    setProduct((prevData) => ({
      ...prevData,
      variant: [
        ...prevData?.variant,
        { variantTitle: "", variant: "", variantImages: "" },
      ],
    }));
  };

  const handleRemoveVariant = (indexToRemove) => {
    setProduct((prevData) => ({
      ...prevData,
      variant: prevData?.variant.filter((_, index) => index !== indexToRemove),
    }));
  };
  const handleTopDealsProduct = async () => {
    try {
      const response = await callApi("get", "admin/products?type=admin");
      const mainData = response?.data?.data?.data;
      const TopDealsProduct = mainData.filter(
        (db) => db?.categoryId === product?.category?.categoryId
      );
      const top5Deals = TopDealsProduct.slice(0, 5);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    handleTopDealsProduct();
  }, [product?.category?.categoryId]);

  const handleDelete = (index, fileInputRef) => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setImageFiles((prevData) => {
      const newArray = [...prevData];
      let obj = newArray[index];
      obj.files = null;
      return newArray;
    });
  };

  const handleDeleteVariantImage = (index, fileInputRef) => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setProduct(() => {
      const newArray = { ...product };
      let obj = newArray.variant[index];
      obj.variantImages = null;
      return newArray;
    });
  };

  return (
    <>
      <div
        style={{
          padding: "20px",
          display: "flex",
          gap: "20px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className="pro containers d-flex flex-column justify-content-center align-items-start rounded"
          style={{
            width: "90%",
            height: "100%",
            boxShadow: "rgb(113 122 131 / 11%) 0px 7px 30px 0px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            px={5}
            pb={5}
          >
            <Box>
              <Typography
                className="My-Profile"
                variant="h6"
                sx={{ marginTop: "20px" }}
              >
                Product Details
              </Typography>
              <Stack
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={3}
              >
                {/* <Typography fontWeight={"semibold"}>isActive</Typography> */}
                <NavBar
                  width={"50px"}
                  isActive={product?.isActive}
                  handleChange={handleIsActiveChange}
                />
              </Stack>
            </Box>
            <Button
              variant="contained"
              color="success"
              onClick={uploadProductApi}
            >
              Upload Product
            </Button>
          </Box>
          <Grid container columnSpacing={7} px={5} rowSpacing={3}>
            {/* <Grid item xs={12} sm={6}> */}
            {/* <Typography className="My-Profile" sx={{ marginTop: "20px" }}>Product Details</Typography> */}
            {/* <NavBar isActive={product?.isActive} handleChange={handleIsActiveChange} /> */}
            {/* </Grid> */}
            {/* <Grid item xs={12} sm={6} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: "20px" }}> */}
            {/* <Button sx={{ "&:hover": { color: "black" }, background: "black", color: "white" }} onClick={uploadProductApi}>Upload Product</Button> */}
            {/* </Grid> */}
            <Grid item xs={12} sm={6}>
              <Typography className="InputLabel">
                Product Name<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                className="lorem"
                type="text"
                value={product?.productName}
                name="productName"
                onChange={handleProduct}
              />
            </Grid>
            <Grid item xs={12} gap={4} sm={6}>
              <Typography className="InputLabel">
                {variantId ? "Variant Title" : "Product Title"}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                className="lorem"
                type="text"
                value={product?.productTitle}
                name="productTitle"
                onChange={handleProduct}
              />
            </Grid>
            <Grid
              item
              component={Stack}
              direction={"column"}
              xs={12}
              gap={3}
              sm={6}
            >
              <FormControl>
                <Typography className="InputLabel">
                  Category<span style={{ color: "red" }}>*</span>
                </Typography>
                <MultiLevelDropdown
                  selectedCategory={product}
                  onSelectedCategory={setProduct}
                  placeholder={"Select a category"}
                  options={category}
                />
              </FormControl>
              <Grid container justify="center">
                <Grid item>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleOpen}
                  >
                    Add Category
                  </Button>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    closeAfterTransition
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                        boxShadow: 24,
                        p: 4,
                        overflowY: "auto",
                        overflowX: "hidden",
                        maxHeight: "80vh",
                        width: "67%",
                      }}
                    >
                      <AddCategory setOpen={setOpen} />
                    </Box>
                  </Modal>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography className="InputLabel">
                Specification<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                className="lorem"
                type="text"
                name="specification"
                value={product?.specification}
                onChange={handleProduct}
              ></TextField>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography className="InputLabel">
                Price<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                className="lorem"
                type="text"
                name="price"
                value={product?.price}
                onChange={handleProduct}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="InputLabel">Discount Price</Typography>
              <TextField
                className="lorem"
                type="text"
                name="discountPrice"
                value={product?.discountPrice}
                onChange={handleProduct}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="InputLabel">
              Composition & Care<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                className="lorem"
                type="text"
                name="highlights"
                value={product?.highlights}
                onChange={handleProduct}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="InputLabel">
                Total Stock<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                className="lorem"
                type="text"
                name="quantity"
                value={product?.quantity}
                onChange={handleProduct}
              ></TextField>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography>
                Seller<span style={{ color: "red" }}>*</span>
              </Typography>
              <Select
                // className="form-control state-event"
                style={{
                  color: "black",
                  width: "100%",
                  fontSize: "14px",
                }}
                id="seller"
                name="vendor"
                value={product.vendor || ""}
                onChange={handleProduct}
                displayEmpty
                IconComponent={KeyboardArrowDownIcon}
              >
                {sellers.map((seller) => (
                  <MenuItem key={seller.id} value={seller.id}>
                    {seller.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="InputLabel">
                Tags<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                className="lorem"
                type="text"
                name="tag"
                value={product?.tag}
                onChange={handleProduct}
              ></TextField>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography
                sx={{
                  color: "black",
                  fontWeight: "semibold",
                  fontSize: "15px",
                }}
                variant="subtitle2"
              >
                Description<span style={{ color: "red" }}>*</span>
              </Typography>
              <>
                <ProductForm
                  inputData={inputData}
                  setInputData={setInputData}
                />
                <Box sx={{ padding: "20px" }} />
                <Box
                  sx={{
                    display: "flex",
                    padding: "8px 12px",
                    gap: "8px",
                  }}
                ></Box>
              </>
            </Grid>
            <Grid item xs={12} sm={12}>
              {imageFiles && imageFiles ? (
                <>
                  <Typography className="InputLabel">
                    Upload Images & Videos
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  {imageFiles?.map((file, index) => (
                    <UploadCompFollowUp
                      key={index}
                      index={index}
                      file={imageFiles[index].files}
                      name={`upload${index}`}
                      handleImageChange={handleImageChange}
                      handleDelete={handleDelete}
                      buttonText={`Upload Image ${index + 1}`}
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "0.5rem",
                        width: "100%",
                        color: "black",
                        border: "1px dashed grey",
                        height: "3rem",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: `white`,
                        },
                      }}
                      textSize="16px"
                      handleClick={() => handleDeleteModel(index)}
                      variant={"contained"}
                    />
                  ))}
                  {imageFiles.length > 0 ? (
                    <Box sx={{ paddingBottom: "30px", width: "100%", mt: 5 }}>
                      <Button
                        onClick={() => handleCallModel("imageFiles")}
                        variant="contained"
                        sx={{
                          border: "1px solid black",
                          color: "black",
                          borderRadius: "8px",
                          backgroundColor: "white",
                        }}
                      >
                        Add More Images
                      </Button>
                    </Box>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <></>
              )}
            </Grid>

            {variantId && (
              <Grid item xs={12} sm={6}>
                <Typography>Variant</Typography>
                <TextField
                  sx={{ marginBottom: "30px", width: "100%" }}
                  name="variant"
                  value={product.variant}
                  onChange={handleProduct}
                />
              </Grid>
            )}

            {/* <Grid item xs={12} sm={6}>
                            <Typography sx={{marginTop:"20px"}}>Variants</Typography>
                            {product?.variant?.length > 0 && product?.variant?.map((data, index) => (
                                <div style={{marginTop:"20px"}} key={index}>
                                    <div>
                                        <Typography className="InputLabel">Title<span style={{ color: "red" }}>*</span></Typography>
                                        <TextField className="lorem" type="text" name='variantTitle' value={data?.variantTitle} onChange={(e) => handleProduct(e, index)}></TextField>
                                    </div>
                                    <div>
                                        <Typography className="InputLabel">Variant<span style={{ color: "red" }}>*</span></Typography>
                                        <TextField className="lorem" type="text" name='variant' value={data?.variant} onChange={(e) => handleProduct(e, index)}></TextField>
                                    </div>
                                    {variantId ? 
                                    <div>
                                        <UploadCompFollowUp
                                            index={index}
                                            use={2}
                                            file={product.variant[index].variantImages}
                                            name={`variantImage${index}`}
                                            handleImageChange={handleProduct}
                                            handleDelete={handleDeleteVariantImage}
                                            buttonText={`Upload Image for Variant ${index + 1}`}
                                            sx={{
                                                backgroundColor: 'white',
                                                borderRadius: '0.5rem',
                                                width: '100%',
                                                color: 'black',
                                                border: '1px dashed grey',
                                                height: '3rem',
                                                textTransform: 'none',
                                                '&:hover': {
                                                    backgroundColor: `white`
                                                }
                                            }}
                                            textSize="16px"
                                            handleClick={() => handleCallModel('variantImages', index)}
                                            variant={'contained'}
                                        />
                                    </div>
                                  : <></>}

                                    {index != 0 &&
                                        <button
                                            onClick={() => handleRemoveVariant(index)}
                                            variant="contained"
                                            sx={{ color: 'black', background: 'none' }}
                                        >
                                            <DeleteIcon />
                                        </button>
                                    }
                                </div>

                            ))}
                            <Box sx={{ marginBottom:"30px",marginTop: '50px', width: "100%" }}>
                                <Button
                                    onClick={() => handleAddVariant('variant')}
                                    variant="contained"
                                    sx={{ border: '1px solid black', color: 'black', borderRadius: '8px', backgroundColor: 'white' }}
                                >
                                    + Add More Variants
                                </Button>
                            </Box>
                        </Grid> */}
          </Grid>
        </div>
      </div>
      <CustomizedSnackbars msgData={snackBarMsg} setMsgData={setSnackBarMsg} />
    </>
  );
};
export default AllProducts;
