import React, { useState } from 'react';
import '../src/loginPage.css';
import { callApi } from './callApi/api';
import { InputAdornment, IconButton } from '@mui/material'; // Import the necessary components
import Visibility from '@mui/icons-material/Visibility'; // Import the eye icon
import VisibilityOff from '@mui/icons-material/VisibilityOff'


export default function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [disable, setDisable] = useState(true)

  const handleData = async (event) => {
    try {
      event.preventDefault();
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+}{:;'?/>.<,])[A-Za-z\d!@#$%^&*()_+}{:;'?/>.<,]{6,}$/
  
      // Validation
      if (!emailPattern.test(email.trim())) {
        setErrorMessage('Please enter a valid email address.');
        return
      }else if(!password){
        setErrorMessage('Password is empty');
        return
      }
      // } else if (!passwordPattern.test(password)) {
      //   setErrorMessage(
      //     'Password must be at least 6 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character.'
      //   );
      //   return
      // }

      const obj = { contact: email, password: password }
      const response = await callApi("post", 'admin/login', obj);
      if (response?.data?.success) {
        const token = response?.data?.data?.token;
        const data = response?.data?.data?.user;
        localStorage.setItem("token", JSON.stringify(token));
        localStorage.setItem("userdata", JSON.stringify(data));
        if (token) {
          window.location.href = '/ecommerce';
        }
      }else{
        setErrorMessage(response?.data?.message)
      }
    }
    catch (error) {
      console.log(error)
    }
  }
  const handleLogin = (event) => {
    event.preventDefault();
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+}{:;'?/>.<,])[A-Za-z\d!@#$%^&*()_+}{:;'?/>.<,]{6,}$/

    // Validation
    if (!emailPattern.test(email.trim())) {
      setErrorMessage('Please enter a valid email address.');
    } else if (!passwordPattern.test(password)) {
      setErrorMessage(
        'Password must be at least 6 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character.'
      );
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const handleEmail = (e) => {
    setDisable(false)
    const value = e.target.value
    setEmail(value)
    if (!value) {
      setDisable(true)
    }
  }

  return (
    <>
      <form >
        <div class="container">
          <h1>Admin Login</h1>
          <h2>Email </h2>
          <input
            type="text"
            placeholder="Enter Email"
            name="email"
            value={email}
            onChange={handleEmail}
          />
          <h2>Password </h2>
          <div style={{ position: 'relative' }}>
            <input
              disabled={disable}
              type={showPassword ? "text" : "password"}
              placeholder="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ paddingRight: '2.5rem' }}
            />
            <IconButton
              style={{ position: 'absolute', right: '5px', top: '50%', transform: 'translateY(-50%)' }}
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </div>
          <button disabled={disable} className='send' type="submit" onClick={handleData}>Login</button>
          {errorMessage && <div className="error">{errorMessage}</div>}
          {/* <div className='checkbox1'>
            <div className='remember'><input type="checkbox" checked="checked" /> Remember me <br /> <br />
            </div>
          </div> */}
        </div>
      </form>
    </>
  );
}