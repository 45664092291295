import React, { useEffect } from "react";
import "./Styles/style.css"
import { Typography } from "@mui/material";
// import { callApi } from "../../common/api";
// import Loader from "../../common/components/Loader";
import { Avatar } from "@mui/material";
import { callApi } from "../../callApi/api";

const AdminProfile = () => {
    const genders = [
        'Male',
        'Female',
        'Other'
]
    const [user, setUser] = React.useState({
        name: "",
        dob: "",
        email: "",
        gender: "",
        mobile: "",
    })
    const [pic, setPic] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(false)
    const handleProfile = async () => {
        const response = await callApi("get", `admin/getProfile`);
        console.log(response.data.data,'--------response----');
        setUser({
            name: response?.data?.data?.adminProfile?.name,
            dob: response?.data?.response?.data?.dob,
            gender: genders[response?.data?.data?.adminProfile?.gender],
            email: response?.data?.data?.email,
            dob: response?.data?.data?.adminProfile?.dob
        })
    }
    useEffect(() => {
        handleProfile()
    }, [])

    const editProfile = () => {
        window.location.href = "/editProfile"
    }

    return (
        <>
            <div style={{ padding: "20px", display: 'flex', gap: '20px', alignContent: 'center', justifyContent: 'center', }}>

                <div className="pro containers d-flex flex-column justify-content-center align-items-start rounded" style={{ width: "90%", height: '100%', boxShadow: 'rgb(113 122 131 / 11%) 0px 7px 30px 0px' }}>
                    <div className="Frame427319601">
                        <div>
                            <Avatar ></Avatar>
                            <Typography className="My-Profile" sx={{ paddingTop: '10px' }}>My Profile</Typography>
                        </div>
                        <div className="Frame427319602">
                            <button onClick={editProfile} className="SmallButton" style={{ color: "black" }}>Edit</button>
                        </div>
                    </div>
                    <div className="Frame427319637">

                        <div className="Frame427319636">
                            <Typography className="NeelKapadia">{user.name}</Typography>
                            <Typography className="neelkapadia123gmail">{user?.email}</Typography>
                        </div>
                    </div>
                    <div className="Frame427319585">
                        <div className="Frame427319576">
                            <div className="Frame427319574">
                                <div className="Frame427319633">
                                    <Typography className="InputLabel">Name</Typography>
                                    <Typography className="lorem">{user?.name}</Typography>
                                </div>
                                <div className="Frame427319633">
                                    <Typography className="InputLabel">Email</Typography>
                                    <Typography className="lorem">{user?.email}</Typography>
                                </div>
                            </div>
                            <div className="Frame427319574">
                                <div className="Frame427319633">
                                    <Typography className="InputLabel">Gender</Typography>
                                    <Typography className="lorem">{user.gender}</Typography>
                                </div>
                                <div className="Frame427319633">
                                    <Typography className="InputLabel">Mobile Number</Typography>
                                    <Typography className="lorem">{user?.mobile}</Typography>
                                </div>
                            </div>
                            <div className="Frame427319574">
                                <div className="Frame427319633">
                                    <Typography className="InputLabel">Date Of Birth</Typography>
                                    <Typography className="lorem">{user.dob}</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminProfile; 