import React, { useEffect, useState, useRef } from "react";
import Typography from "@mui/material/Typography";
import { Button, Grid } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";

const UploadCompFollowUp = (props) => {
  const {
    use,
    bro,
    file,
    handleImageChange,
    name,
    sx,
    label,
    buttonText,
    textSize,
    xs,
    handleDelete,
    index,
    docSubmit,
    handleClick,
  } = props;
  const [lastSegment, setLastSegment] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    var flag = false;
    if (file && !flag) {
      // if (file.links) {
      //     setFilePreview(file);
      //     const segments = file?.link.split('.');
      //     const extension = segments[segments.length - 1];
      //     setLastSegment(extension);
      // }
      if (file.name) {
        const segments = file.name && file.name.split(".");
        const extension = segments[segments.length - 1];
        setLastSegment(extension);

        const reader = new FileReader();
        reader.onloadend = () => {
          setFilePreview(reader.result);
        };
        reader.readAsDataURL(file);
        flag = true;
      }
    } else {
      setFilePreview(null);
      setLastSegment(null);
    }
  }, [file]);

  const allowedExtensions = ["png", "jpeg", "jpg", "svg"];

  const text = buttonText ? buttonText : "Upload";
  const buttonSx = sx
    ? sx
    : {
        width: "12rem",
        border: "1px dashed grey",
        height: "2.5rem",
        textTransform: "none",
        "&:hover": {
          backgroundColor: `black`,
        },
      };

  return (
    <Grid
      sx={{
        minHeight: "100px",
        maxHeight: "200px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
      item
      xs={xs ? xs : 12}
    >
      <Typography
        sx={{
          color: "grey",
          fontSize: "11px",
          height: "10px",
          marginBottom: "10px",
        }}
        variant="subtitle2"
      >
        {label}
      </Typography>
      <input
        type="file"
        ref={fileInputRef}
        id={name}
        accept=".pdf, .mp4, .avi, .mov, video/*, image/*"
        style={{ display: "none" }}
        name={name}
        onChange={(e) => {
          handleImageChange(e, index);
        }}
      />
      <label htmlFor={name}>
        <Button className="" sx={buttonSx} variant="contained" component="span">
          <UploadIcon style={{ fontSize: "17px", color: "black" }} />
          &nbsp;
          <span
            style={{ fontSize: textSize ? textSize : "10px", color: "black" }}
          >{`${text}`}</span>
        </Button>
      </label>

      {filePreview && (
        <div className="d-flex justify-content-between align-items-center">
          {allowedExtensions.includes(lastSegment) ? (
            <img
              src={filePreview}
              alt="File Preview"
              style={{ width: "100px", height: "100px" }}
              className="mt-4"
            />
          ) : (
            <DescriptionIcon
              style={{ marginTop: "10px", height: "40px", width: "40px" }}
            />
          )}

          <DeleteIcon
            sx={{ cursor: "pointer" }}
            onClick={() =>
              docSubmit
                ? handleDelete(fileInputRef, index)
                : handleDelete(index, fileInputRef)
            }
            className="text-danger"
          />
        </div>
      )}
      {bro === 1 && index > 0 ? (
        <div className="d-flex justify-content-between align-items-center">
          <DeleteIcon
            sx={{ cursor: "pointer" }}
            onClick={() => handleClick(name)}
          />
        </div>
      ) : bro === undefined && index != 0 && use != 2 ? (
        <div className="d-flex justify-content-between align-items-center">
          <DeleteIcon
            sx={{ cursor: "pointer" }}
            onClick={() => handleClick(name)}
          />
        </div>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default UploadCompFollowUp;
