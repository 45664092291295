import axios from "axios";
// to call get post put delete API's
/*
METHOD : STRING
ENDPOINT : STRING
DATA : OBJECT
ILOCAT : BOOLEAN
HEADERS :
 */
export const callApi = async (
  method,
  endPoint,
  data = {},
  header,
  isLocal = true,
  isFormData = false
) => {
  try {
    let token = localStorage.getItem("token");
    const parsedToken = JSON.parse(token);
    let // import "./Styles/style.css";
      headers = {
        authorization: `Bearer ${parsedToken}`,
        ...header,
      };
    let localhost = isLocal
      ? `${process.env.REACT_APP_COMMON_API}/${endPoint}`
      : endPoint;
    if (method === "get" || method === "delete") {
      const response = await axios[method](localhost, { headers });
      return response;
    } else {
      const response = await axios[method](
        localhost,
        isFormData ? data : { ...data },
        { headers }
      );
      return response;
    }
  } catch (err) {
    if (
      err?.response?.data?.code == 401 &&
      err?.response?.data?.message == "Unauthorized to use this route2"
    ) {
      localStorage.clear();
      window.location.href = `${process.env.REACT_APP_LANDING_PAGE}/login?type=startup&expireSession=true`;
    }
    return err;
  }
};
