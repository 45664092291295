import {
  TextField,
  Typography,
  Box,
  Button,
  Select,
  Grid,
  Modal,
} from "@mui/material";
import React, {  useEffect, useState } from "react";
import "../All-products/Styles/style.css";
import ProductForm from "../All-products/ProductForm";
import { useLocation } from "react-router-dom";
import CustomizedSnackbars from "../Snackbar";
import UploadCompForSellerDetails from "../../UploadCompForSeller";
import { callApi } from "../../callApi/api";

const Seller = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sellerId = searchParams.get('sellerId');
  // console.log(sellerId);
  

  const [sellerDetails, setSellerDetails] = useState({
    name: "",
    email: "",
    phone: "",
    uploadImage: "",
    description: "",

  });
  
  const [snackBarMsg, setSnackBarMsg] = React.useState({
    message: "",
    severity: "success",
    open: false,
  });

  const [inputData, setInputData] = React.useState({
    description: "",
  });

  const [imageIndex, setImageIndex] = useState(0);
  const [imageFiles, setImageFiles] = useState([{ files: null }]);

  const handleImageChange = (event, index) => {
    const newFilesData = [...imageFiles];
    newFilesData[index].files = event.target.files[0];
    setImageFiles(newFilesData);
    setImageIndex(index);
  };

  useEffect(() => {
    const getSellerDetails = async () => {
      const response = await callApi(
        "get",
        `seller/getSeller?id=${sellerId}`
      );
      const data = response?.data?.data;
    
      if(data){
        setSellerDetails({
          ...sellerDetails,
          name:data?.name,
          email:data?.email,
          phone:data?.phone,
        });
        setInputData({
          description:data?.description
        });
        setImageFiles([{ files: response?.data?.data?.imageUrl }]);
      }
    }
    getSellerDetails();
  },[]);
 
  const uploadSellerDetails = async () => {
    try{
      if (!sellerDetails.name || !sellerDetails.phone) {
        setSnackBarMsg({
            message: "Please fill all required fields",
            severity: "error",
            open: true
        })
        return
    }

      const formData = new FormData();
      formData.append('id',sellerId);
      formData.append('name', sellerDetails?.name);
      formData.append('email', sellerDetails?.email);
      formData.append('phone', sellerDetails?.phone);
      formData.append('description', inputData?.description);
      formData.append('files',imageFiles[0].files);      
      
      const response = await callApi(
        "post",
        "seller/sellerDetail",
        formData,
        {"content-type":"multipart/form-data"},
        true,
        true
      );
      // console.log(response);
      window.location.href = "seller";
    }catch(error){
      console.log(error);
    }
  }
  const handleSellerDetails = (event) => {
    setSellerDetails((prevData) => ({
      ...prevData,
      [event.target.name]: event.target.value,
    }));
  };
  const handleDeleteModel = (index) => {
    const newInputData = [...imageFiles];
    newInputData.splice(index, 1);
    setImageFiles(newInputData);
    setInputData(newInputData);
  };
  const handleDelete = (index, fileInputRef) => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setImageFiles((prevData) => {
      const newArray = [...prevData]
      let obj = newArray[index]
      obj.files = null
      return newArray
    })
  };

  return (
    <>
      <div
        style={{
          padding: "20px",
          display: "flex",
          gap: "20px",
          paddingBottom: "16px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className="pro containers d-flex flex-column justify-content-center align-items-start rounded"
          style={{
            paddingTop: "25px",
            paddingBottom: "20px",
            width: "90%",
            height: "100%",
            marginBottom: "16px",
            boxShadow: "rgb(113 122 131 / 11%) 0px 7px 30px 0px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
            <Box sx={{ marginLeft: "40px", marginBottom: "30px", }}>
            </Box>
            <Button sx={{ "&:hover": { color: "black" }, background: "black", color: "white", height: "fit-content", width: "fit-content", marginTop: "30px", marginRight: "40px" }} onClick={uploadSellerDetails}>Upload Details</Button>
          </Box>
          <Grid container columnSpacing={7} px={5} rowSpacing={3}>

            <Grid item xs={12} gap={2} sm={6}>
              <Typography className="InputLabel">
                Seller Name<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                className="lorem"
                type="text"
                value={sellerDetails?.name}
                name="name"
                onChange={handleSellerDetails}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="InputLabel">
                Seller Email<span style={{ color: "red" }}></span>
              </Typography>
              <TextField
                className="lorem"
                type="text"
                value={sellerDetails?.email}
                name="email"
                onChange={handleSellerDetails}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="InputLabel">
                Seller Number<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                className="lorem"
                type="text"
                value={sellerDetails?.phone}
                name="phone"
                onChange={handleSellerDetails}
              ></TextField>
            </Grid>




            <Grid container columnSpacing={7} px={5} rowSpacing={3} marginTop={4}>
              <Grid item xs={6}>

                <Typography className="InputLabel"

                >
                  Description<span style={{ color: "red" }}>*</span>
                </Typography>
                <>
                  <React.Fragment>
                    <ProductForm
                      inputData={inputData}
                      setInputData={setInputData}
                    />
                  </React.Fragment>

                </>
              </Grid>
              <Grid item xs={6}>

                {imageFiles && imageFiles ? (
                  <>
                    <Typography className="InputLabel">
                      Upload Image<span style={{ color: "red" }}>*</span>
                    </Typography>
                    {imageFiles?.map((file, index) => (
                      <UploadCompForSellerDetails
                        index={index}
                        file={imageFiles[index].files}
                        name={`upload${index}`}
                        handleImageChange={handleImageChange}
                        handleDelete={handleDelete}
                        buttonText={`Upload Image`}
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "0.5rem",
                          width: "100%",
                          color: "black",
                          border: "1px dashed grey",
                          height: "2rem",
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: `white`,
                          },
                        }}
                        textSize="16px"
                        handleClick={() => handleDeleteModel(index)}
                        variant={"contained"}
                      />
                    ))}

                  </>
                ) : (
                  <></>
                )}
              </Grid>

            </Grid>
            <Grid item xs={12} sm={12}>

            </Grid>
          </Grid>
        </div>
      </div>
      <CustomizedSnackbars msgData={snackBarMsg} setMsgData={setSnackBarMsg} />
    </>
  );
};
export default Seller;
