import React from "react"

const HeaderLayout = ({children}) => {
    return (
        <>
          
            <div style={{width:"100%",backgroundColor:"white", padding:"10px" }}>
                <div style={{display:"flex", justifyContent:"space-between"}}>
               <img style={{height:"50px", width:"",}} src="Bamkart.png-removebg-preview.png" alt="preview"></img>
               <p style={{color:"#2D562B", fontSize:"37px", fontWeight:"bold", marginTop:"5px"}}>Admin Panel</p>
               </div>
            </div>

            <div>
               {children}
            </div>
        </>
    )
}

export default HeaderLayout;