import React, { useEffect, useState } from "react";
import { FormControl, FormLabel, Select, MenuItem, Grid } from "@mui/material";
import { callApi } from "../callApi/api";

const CategorySelect = ({ formik }) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await callApi("get", "admin/getCategories");
        setCategories(response?.data?.data || []);
      } catch (error) {
        console.error("Failed to fetch categories:", error);
      }
    };
    fetchCategories();
  }, []);

  return (
    <FormControl component={Grid} item xs={12} lg={6} required>
      <FormLabel>Categories</FormLabel>
      <Select
        onBlur={formik.handleBlur}
        error={formik.touched.url && Boolean(formik.errors.url)}
        name="url"
        value={formik.values.url}
        onChange={formik.handleChange}
        inputProps={{ style: { display: "none" } }}
        MenuProps={{
          PaperProps: {
            style: {
              overflowX: "scroll",
              minWidth: "380px",
              top: "79px",
              bottom: "200px",
              left: "719px",
              transform: "none",
              opacity: 1,
              transformOrigin: "190px 21.8767px",
              borderRadius: "0.5rem",
              transition:
                "opacity 295ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 196ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            },
          },
        }}
      >
        {categories.map((category) => (
          <MenuItem key={category.id} value={category.id}>
            {category.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CategorySelect;
