import React, { useState, useEffect } from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { callApi } from '../../callApi/api';
import InboxIcon from '@mui/icons-material/Inbox';
import { Grid, TextField, Typography, Box, Button } from '@mui/material';
import NavBar from '../../ToggleButton';
import CustomizedSnackbars from '../Snackbar';

export default function MultiLevelMenu({ onClose, setOpen }) {
    const [categories, setCategories] = useState([]);
    const [categoryData, setCategoryData] = useState({
        name: "",
        isActive: false
    })

    const [snackBarMsg, setSnackBarMsg] = React.useState({
        message: "",
        severity: "success",
        open: false,
      });

    const closeModal = () => {
        setOpen(false)
    }
    const [catId, setCatId] = useState("")
    const [categoryName, setCategoryName] = useState("")
    const createCategory = async () => {
        const obj = {
            name: categoryData?.name,
            // isActive: categoryData?.isActive,
            isActive: 1,
            parentId: catId ? catId : undefined
        }
        try {
            if(!categoryData.name){
                setSnackBarMsg({
                    message:"Please fill the required field",
                    severity:"error",
                    open: true
                })
                return
            }
            const response = await callApi('post', 'admin/createCategories', obj)
            if (response.data.success === true) {
                window.location.reload()
            }
        } catch (err) {
            console.log(err)
        }
    }
    const handleChange = (event) => {
        const { name, value } = event.target
        if (name === "name") {
            setCategoryData((prevData) => ({
                ...prevData,
                [name]: value
            }))
        }
    }
    const handleCategories = async () => {
        try {
            const response = await callApi('get', 'admin/getCategories');
            const data = response?.data?.data
            setCategories(data);
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        handleCategories()
    }, [])
    const handleSubCategoryToggle = (categoryId) => {
        setCategories(categories.map(category => ({
            ...category,
            open: category.id === categoryId ? !category.open : category.open
        })));
    };
   
    const handleAddButton = (categoryId, categoryName) => {
        setCatId(categoryId)
        // setShowInput(true)
        setCategoryName(categoryName)
    }; 
    const handleDeletButton = async (categoryId, categoryName) => {
        console.log(categoryName, categoryId, 'handleDeletButton');
        const response = await callApi('delete', `admin/deleteCategory?id=${categoryId}`);
        if (response.data.success === true) {
            window.location.reload();
        }else(
                setSnackBarMsg({
                    message:"Unable to delete category",
                    severity:"error",
                    open: true
                })

        )
    }
    const handleIsActiveChange = () => {
        setCategoryData((prevCategoryData) => ({
            ...prevCategoryData,
            isActive: !prevCategoryData?.isActive
        }));
    };
    const renderCategory = (categoryId) => {
        const category = categories.find(cat => cat.id === categoryId);
        if (!category) return null;
        const hasSubcategories = categories.some(cat => cat.parentId === categoryId);

        return (
            <React.Fragment key={category.id}>
                <ListItemButton sx={{ background: catId === category?.id ? "gray" : "none" }} onClick={() => handleSubCategoryToggle(category.id)}>
                    <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary={category.name} onClick={() => handleAddButton(category.id, category.name)} />
                    {hasSubcategories && (category.open ? <ExpandLess /> : <ExpandMore />)}
                    {!hasSubcategories ? <Button onClick={() => handleDeletButton(category.id, category.name)}>Deleted</Button>:<></>}

                </ListItemButton>
                <Collapse in={category.open} timeout="auto" unmountOnExit>
                    {category.open && renderSubCategories(category.id)}
                </Collapse>
            </React.Fragment>
        );
    };


    const renderSubCategories = (parentId) => {
        const subCategories = categories.filter(category => category.parentId === parentId);
        if (subCategories.length === 0) return null;

        return (
            <List component="div" disablePadding sx={{ paddingLeft: "40px" }}>
                {subCategories.map(subCategory => (
                    <React.Fragment key={subCategory.id}>
                        {renderCategory(subCategory.id)}
                    </React.Fragment>
                ))}
            </List>
        );
    };

    return (
        <>
        <div style={{padding: "20px", display: 'flex', gap: '20px', alignItems: 'center', justifyContent: 'center', height: "100%", }} >
            <div className="pro containers d-flex flex-column justify-content-center align-items-start rounded" style={{ width: "100%", height: '100%', boxShadow: 'rgb(113 122 131 / 11%) 0px 7px 30px 0px', padding: "40px" }}>
                <Grid container columnSpacing={7} px={5} rowSpacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{ display: "grid", gap: "32px" }}>
                            <Typography sx={{ fontWeight: "600", fontSize: "1.3rem" }}>Edit Category</Typography>
                            <NavBar isActive={categoryData?.isActive} handleChange={handleIsActiveChange} />
                            <Box>
                                <Typography sx={{ fontSize: "20px" }}>Add Category In: {categoryName ? categoryName : 'Header'}</Typography>
                                <TextField value={categoryData?.name} name='name' onChange={handleChange} />
                            </Box>
                            <Box sx={{display:"flex", gap:"30px"}}>
                                <Button onClick={createCategory} sx={{
                                    background: "black",
                                    color: "white",
                                    '&:hover': {
                                        background: 'gray',
                                        color: "black"
                                    }
                                }}>Save</Button>


                                {setOpen != undefined && 

                                <Button onClick={closeModal} sx={{
                                    background: "white",
                                    border:"1px solid black",
                                    color: "black",
                                    '&:hover': {
                                        background: 'gray',
                                        color: "black"
                                    }
                                }}>Cancel</Button>
                            }
                            </Box>
                        </Box>
                    </Grid>
                    <List
                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                Add categories
                            </ListSubheader>
                        }
                    >
                        {categories
                            .filter(category => category.parentId === null)
                            .map(mainCategory => (
                                <React.Fragment key={mainCategory.id}>
                                    {renderCategory(mainCategory.id)}
                                </React.Fragment>
                            ))}
                    </List>
                </Grid>
            </div>
        </div>
        <CustomizedSnackbars msgData={snackBarMsg} setMsgData={setSnackBarMsg} />
        </>
    );
}
