import { Box, TextField, Button, Stack, Typography, } from '@mui/material'
import Modal from '@mui/material/Modal'
import { borderRadius } from '@mui/system'
import React, { useState } from 'react'
import { callApi } from '../../callApi/api'
import CustomizedSnackbars from '../Snackbar'

const style = {
    position: "absolute",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'white',
    borderRadius: 3,
    boxShadow: 24,
}
const AddInventory = ({ open, handleClose, id }) => {
    // const [quantity, setQuantity] = useState();
    // const [QaBy, setQaBy] = useState();
    const [data, setData] = useState({
        quantity:0,
        QaBy:'',
        productId: id
    })
    
    const [snackBarMsg, setSnackBarMsg] = React.useState({
        message: "",
        severity: "success",
        open: false,
      });

    const handleSave = async () => {
        try {
            if(!data.quantity){
               setSnackBarMsg({
                  message : "Please fill the field",
                  severity:"error",
                  open:true
               })
               return
            }
            const response = await callApi('post', '/admin/createInventory', data)
            handleClose();
        }
        catch (error) {
            console.log(error, 'error')
        }
    }

    const handleChangeInput = (e) => {
        const {name} = e.target
        if(name === "quantity"){
            if(!/^\d+$/.test(e.key) && e.key !== "Backspace" && e.key !== "Shift" && e.key !== "Tab"){
                e.preventDefault();
                 setSnackBarMsg({
                    message : "Enter Digits only",
                    severity:"error",
                    open : true 
                 })
                 return
            }
        }
    }
  
    const handleChange = (e) =>{
        const {name,value} = e.target;
        setData(prevData=> ({
            ...prevData,
            [name]:value
        }))
    }
    
    return (
        <>
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Stack sx={{ ...style }} gap={6} padding={5}>
                <Typography variant='h5'>Add New Inventory</Typography>
                <Stack direction={'row'} gap={3}>
                    <TextField
                        onKeyDown={handleChangeInput}
                        name="quantity"
                        value={data.quantity}
                        onChange={ handleChange}
                        fullWidth label="Quantity" variant="outlined" />
                    <TextField 
                    name="QaBy"
                    value ={data.QaBy}
                    onChange={handleChange}
                    fullWidth label="qaBy" variant="outlined" />
                </Stack>
                <Stack direction="row" gap={2} justifyContent={'end'}>
                    <Button variant='outlined' onClick={handleClose}>Cancel</Button>
                    <Button variant='contained' onClick={handleSave}>Save</Button>
                </Stack>
            </Stack>
        </Modal>
        <CustomizedSnackbars msgData={snackBarMsg} setMsgData={setSnackBarMsg} />
        </>
    )
}

export default AddInventory