import {
    TextField,
    Select,
    Menu,
    MenuItem,
    FormControl,
    FormLabel,
    CircularProgress,
  } from "@mui/material";
  import EditIcon from "@mui/icons-material/Edit";
  import { Grid, Popover } from "@mui/material";
  import { Header } from "../../components/index.js";
  import React, { useEffect, useState } from "react";
  import "../Banner/style.css";
  import DataGridTable from "../../components/DataGrid/DataGridTable.js";
  import ToggleButton from "../../ToggleButton.js";
  import CustomizedSnackbars from "../../components/Snackbar.jsx";
  import { callApi } from "../../callApi/api";
  import Box from "@mui/material/Box";
  import Button from "@mui/material/Button";
  import Typography from "@mui/material/Typography";
  import Modal from "@mui/material/Modal";
  import DeleteIcon from "@mui/icons-material/Delete";
  import Swal from "sweetalert2";
  import { useFormik } from "formik";
  import * as Yup from "yup";
  import { HoverComponent } from "../IamgeHowerComponent.jsx";
  import { Stack } from "@mui/system";
import UploadCompFollowUp from "../Banner/ComponentsUpload.js";
  
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    maxWidth: "640px",
    width: "100%",
    px: 4,
    py: 4,
  };
  
  export default function BlogComponent() {
    const validationSchema = Yup.object().shape({
        heading: Yup.string()
        .matches(/^[^\d]+$/, "Name should not contain digits")
        .required("Name is required"),
    });
  
    const validationSchemaForAdd = Yup.object().shape({
        heading: Yup.string()
        .matches(/^[^\d]+$/, "Name should not contain digits")
        .required("Name is required"),
    });
  
    const [banner, setBanner] = useState({
      heading: "",
      subHeading: "",
      isActive: false,
      banner: "",
    });
    const [productValue, setProductValue] = useState("");
  
    const [on, setOn] = React.useState(false);
    const [rows, setRows] = useState([]);
    const [pageSize, setPageSize] = useState(5);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(0);
    const [id, setId] = useState("");
    const [open, setOpen] = React.useState(false);
    const [imageFiles, setImageFiles] = useState([{ files: null }]);
    const [refresh, setRefresh] = useState(false);
    const [imageEdit, setImageEdit] = useState(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState({
      message: "",
      severity: "success",
      open: false,
    });
    const [products, setProducts] = useState([]);
    const [buttonDissable, setButtonDissable] = useState(false);
  
    const getProducts = async () => {
      const response = await callApi("get", "admin/products");
      setProducts(response?.data?.data?.data);
    };
  
    const handlePageChange = (newPage) => {
      setPage(newPage.page + 1);
      setPageSize(newPage.pageSize);
    };
  
    const getApi = async () => {
      try {
        let response;
        if (on) {
          response = await callApi("get", `admin/getBlogs`);
          let data = response?.data?.data[0];
          setBanner(response?.data?.data[0]);
          setImageFiles([{ files: response?.data?.data[0].imageUrl }]);
          setProductValue(data?.url);
          formik.setValues({
            heading: data.heading,
            subHeading: data.subHeading,
            isActive: data.isActive,
            banner: data.banner,
          });
        } else {
          response = await callApi("get", "admin/getBlogs");
          const modifiedData = response?.data?.data?.map((data, index) => ({
            ...data,
            _id: index + 1,
          }));
  
          setRows(modifiedData);
          setTotalRows(response?.data?.data?.length);
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    useEffect(() => {
      getProducts();
    }, [on]);
  
    const handleImageChange = (event) => {
      let newArray = [...imageFiles];
      newArray[0].files = event.target.files[0];
      setImageFiles(newArray);
    };
  
    // const handleDeletes = (id) => {
    //   Swal.fire({
    //     icon: 'warning',
    //     title: 'Are you sure you want to Delete this Banner?',
    //     showCancelButton: true,
    //     confirmButtonText: 'OK',
    //     cancelButtonText: 'Cancel',
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       let response = callApi("post", `admin/deleteBanner?id=${id}`)
    //       if (response) {
    //         setRefresh(!refresh)
    //       }
    //     }
    //   })
    // }
  
    const handleDeletes = async (id) => {
      Swal.fire({
        icon: "warning",
        message: "Are you sure you want to Delete this banner?",
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          let response = callApi("post", `admin/deleteBlogs?id=${id}`);
          if (response) {
            setRefresh(!refresh);
          }
        }
      });
    };
  
    const handleRemoveImage = (index, fileInputRef) => {
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      setImageFiles([{ files: null }]);
    };
  
    const formik = useFormik({
      initialValues: {},
      validationSchema,
      onSubmit: async (values) => {
        if (Object.keys(formik.errors).length !== 0) {
          return;
        } else {
          handleSave();
        }
      },
    });
  
    const formikForAddForm = useFormik({
      initialValues: { ...banner },
      validationSchema: validationSchemaForAdd,
      onSubmit: async () => {
        if (Object.keys(formikForAddForm.errors).length !== 0) {
          return;
        } else {

          handleCategory();
        }
      },
    });
  
    const handleCategory = async () => {
      try {
        setButtonDissable(true);
        if (imageFiles[0].files == null) {
          setSnackBarMsg({
            message: "Please fill all the fields",
            severity: "error",
            open: true,
          });
          return;
        }
  
        let formData = new FormData();
        formData.append("heading", formikForAddForm.values.heading);
        formData.append("subHeading", formikForAddForm.values.subHeading);
        formData.append("isActive", formikForAddForm.values.isActive);
        formData.append("banner", imageFiles[0].files);
        const response = await callApi(
          "post",
          "admin/addBlogs",
          formData,
  
          {
            "content-Type": "multipart/form-data",
          },
          true,
          true
        );
  
        if (response?.data?.success) {
          setOpen(false);
          setButtonDissable(false); 
          setRefresh(!refresh);
        }
      } catch (err) {
        console.log(err);
      }
    };
  
    useEffect(() => {
      getApi();
    }, [on, refresh]);
  
    const handleIsActiveChange = () => {
      if (on) {
        formik.setFieldValue("isActive", !formik.values.isActive);
      } else {
        formikForAddForm.setFieldValue(
          "isActive",
          !formikForAddForm.values.isActive
        );
      }
    };
  
    const handleSave = async () => {
      try {
        const obj = {
          name: formik.values.name,
          url: productValue,
          isActive: formik.values.isActive,
          id: id,
          banner: imageFiles[0].files,
          type: formik.values.type,
        };
        const response = await callApi("post", "admin/updateBanner", obj, {
          "content-Type": "multipart/form-data",
        });
        if (response.status) {
          setImageEdit(false);
          setOn(false);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
  
    const handleOpen = () => {
      setOpen(true);
      setProductValue("");
      formikForAddForm.setValues({
        heading: "",
        subHeading: "",
        isActive: false,
        banner: "",
      });
      setImageFiles([{ files: null }]);
    };
  
    const handleClose = () => {
      setOpen(false);
    };  
    const handleOff = () => {
      setOn(false);
    };
  
    const columns = [
      { field: "_id", headerName: "S.No", width: 80 },
      
      {
        field: "imageUrl",
        headerName: "Image",
        sortable: false,
        width: 150,
        editable: false,
        renderCell: (rowData) => {
          return (
            <div>
              <HoverComponent props={rowData?.row?.imageUrl} />
            </div>
          );
        },
      },
      {
        field: "heading",
        headerName: "Heading",
        sortable: false,
  
        width: 150,
        editable: false,
        renderCell: (rowData) => {
          return <div>{rowData?.row?.heading}</div>;
        },
      },
      {
        field: "subHeading",
        headerName: "Sub Heading ",
        sortable: false,
        width: 300,
        editable: false,
        renderCell: (rowData) => {
          return (
            <div>
              {rowData?.row?.subHeading}
            </div>
          );
        },
      },
  
      {
        field: "isActive",
        headerName: "Is Active",
        sortable: false,
        width: 100,
        editable: false,
        renderCell: (rowData) => {
          return (
            <div>
              <button
                style={{
                  marginTop: "5px",
                  color: "#343D52",
                  border: "1px solid white",
                  borderRadius: "6px",
                  background: rowData?.row?.isActive ? "#90EE90" : "#F49F9F",
                  width: "60px",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {rowData?.row?.isActive ? "Active" : "Inactive"}
              </button>
            </div>
          );
        },
      },
      {
        field: "action",
        headerName: "Action",
        sortable: false,
        width: 150,
        editable: false,
        renderCell: (rowData) => {
          return (
            <div style={{ gap: "20px", width: "fit-content" }}>
              <DeleteIcon
                onClick={() => handleDeletes(rowData?.row?.id)}
                sx={{ color: "black", pointerEvents: "auto", cursor: "pointer" }}
              />
            </div>
          );
        },
      },
    ];
  
    return (
      <div>
        <div className="m-container" style={{ margin: "auto" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Header title="Blogs" />
            <Button
              style={{
                color: "black",
                border: "1px solid black",
                width: "160px",
                height: "40px",
                borderRadius: "4px",
              }}
              onClick={handleOpen}
            >
              Add Blogs
            </Button>
          </div>
  
          <Box sx={{ overflow: "auto" }}>
            <Grid item xs={12}>
              <DataGridTable
                height="80%"
                width="100%"
                textAlign="center"
                data={rows || []}
                columns={columns}
                pageSize={pageSize}
                rowCount={totalRows}
                onPageChange={handlePageChange}
              />
            </Grid>
          </Box>
        </div>
  
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container direction={"row"} spacing={4}>
              <FormControl component={Grid} item xs={12} lg={6} required>
                <FormLabel>Heading</FormLabel>
                <TextField
                  onBlur={formikForAddForm.handleBlur}
                  helperText={formikForAddForm.touched.name && formikForAddForm.errors.heading}
                  error={formikForAddForm.touched.name && formikForAddForm.errors.heading}
                  className="lorem"
                  autoFocus
                  type="text"
                  value={formikForAddForm.values.heading}
                  name="heading"
                  onChange={formikForAddForm.handleChange}
                />
              </FormControl>
              <FormControl component={Grid} item xs={12} lg={6} required>
                <FormLabel>Sub Heading</FormLabel>
                <TextField
                  onBlur={formikForAddForm.handleBlur}
                  helperText={formikForAddForm.touched.type && formikForAddForm.errors.subHeading}
                  error={formikForAddForm.touched.type && formikForAddForm.errors.subHeading}
                  className="lorem"
                  autoFocus
                  type="text"
                  value={formikForAddForm.values.subHeading}
                  name="subHeading"
                  onChange={formikForAddForm.handleChange}
                />
              </FormControl>
              <FormControl component={Grid} item xs={12} lg={6}>
                <ToggleButton
                required
                  isActive={formikForAddForm.values.isActive}
                  handleChange={handleIsActiveChange}
                />
              </FormControl>
              <FormControl component={Grid} item xs={12} lg={6}>
                <FormLabel>Image</FormLabel>
                <UploadCompFollowUp
                  key={imageFiles}
                  file={imageFiles[0].files}
                  name="upload"
                  handleImageChange={handleImageChange}
                  handleDelete={handleRemoveImage}
                  buttonText={`Upload Image`}
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "0.5rem",
                    width: "100%",
                    color: "black",
                    border: "1px dashed grey",
                    height: "3rem",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: `white`,
                    },
                  }}
                  textSize="16px"
                  variant={"contained"}
                />
              </FormControl>
              <Grid
                item
                xs={6}
                lg={12}
                component={Stack}
                justifyContent={"left"}
                gap={3}
              >
                <Button
                  variant={"contained"}
                  color={"success"}
                  fullWidth
                  onClick={formikForAddForm.handleSubmit}
                  disabled={buttonDissable}
                  >
                  {buttonDissable ? <CircularProgress size={24} color="inherit" /> : "Submit"}
                </Button>
                <Button
                  variant={"contained"}
                  color={"secondary"}
                  fullWidth
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal
          open={on}
          onClose={handleOff}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
          <Grid container direction={"row"} spacing={4}>
              <FormControl component={Grid} item xs={12} lg={6} required>
                <FormLabel>Heading</FormLabel>
                <TextField
                  onBlur={formik.handleBlur}
                  helperText={formik.touched.name && formik.errors.heading}
                  error={formik.touched.name && formik.errors.heading}
                  className="lorem"
                  autoFocus
                  type="text"
                  value={formik.values.heading}
                  name="heading"
                  onChange={formik.handleChange}
                />
              </FormControl>
              <FormControl component={Grid} item xs={12} lg={6} required>
                <FormLabel>Sub Heading</FormLabel>
                <TextField
                  onBlur={formik.handleBlur}
                  helperText={formik.touched.name && formik.errors.subHeading}
                  error={formik.touched.name && formik.errors.subHeading}
                  className="lorem"
                  autoFocus
                  type="text"
                  value={formik.values.subHeading}
                  name="subHeading"
                  onChange={formik.handleChange}
                />
              </FormControl>
              <FormControl component={Grid} item xs={12} lg={6}>
                <ToggleButton
                required
                  isActive={formik.values.isActive}
                  handleChange={handleIsActiveChange}
                />
              </FormControl>
              <FormControl component={Grid} item xs={12} lg={6}>
                <FormLabel>Image</FormLabel>
                <UploadCompFollowUp
                  key={imageFiles}
                  file={imageFiles[0].files}
                  name="upload"
                  handleImageChange={handleImageChange}
                  handleDelete={handleRemoveImage}
                  buttonText={`Upload Image`}
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "0.5rem",
                    width: "100%",
                    color: "black",
                    border: "1px dashed grey",
                    height: "3rem",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: `white`,
                    },
                  }}
                  textSize="16px"
                  variant={"contained"}
                />
              </FormControl>
              <Grid
                item
                xs={6}
                lg={12}
                component={Stack}
                justifyContent={"left"}
                gap={3}
              >
                <Button
                  variant={"contained"}
                  color={"success"}
                  fullWidth
                  onClick={formik.handleSubmit}
                  disabled={buttonDissable}
                >
                {buttonDissable ? <CircularProgress size={24} color="inherit" /> : "Submit"}
                </Button>
                <Button
                  variant={"contained"}
                  color={"secondary"}
                  fullWidth
                  onClick={handleOff}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <CustomizedSnackbars msgData={snackBarMsg} setMsgData={setSnackBarMsg} />
      </div>
    );
  }
  